import React from 'react';
import { useField } from 'formik';
import { isValid, parseISO } from 'date-fns';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { hasValue } from './inputHelpers';
import { useTheme } from '@mui/material/styles';

interface IProps {
  name: string
  label: string
  variant?: 'outlined' | 'filled' | 'standard'
  margin?: 'dense' | 'normal' | 'none';
  pickerVariant?: 'inline' | 'dialog' | 'static'
}

const XDateInput = (props: IProps) => {
  const theme = useTheme();
  const { variant, pickerVariant, name, margin, ...rest } = props;
  const [field, meta, helpers] = useField({ name });
  const error = hasValue(meta.error) ? meta.error : undefined;
  const showError = Boolean(error && meta.touched);

  function handleChange(date: Date | null) {
    if (isValid(date)) {
      return helpers.setValue(date?.toISOString());
    }
    return helpers.setValue(null);
  }

  let { value } = field;
  if (typeof value === 'string') {
    try {
      if (isValid(parseISO(value))) {
        // value = parseISO(value);
      } else {
        value = null;
      }
    } catch (e) {
      value = null;
    }
  } else if (typeof value === 'undefined') {
    value = null;
  } else if (!isValid(value)) {
    value = null;
  }
  return (
        <DesktopDatePicker
            {...rest}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    helperText={showError ? error : null}
                    error={Boolean(showError)}
                    onBlur={() => helpers.setTouched(true)}
                    variant={variant}
                    margin={margin}
                />
            )}
            openTo="year"
            value={value}
            onChange={handleChange}
            inputFormat="dd/MM/yyyy"
            PaperProps={{
              style: {
                boxShadow: theme.shadows[10],
                borderRadius: theme.shape.borderRadius,
              }
            }}
        />
  );
};

export default XDateInput;
