/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum AddressCategory {
  Home = "Home",
  Work = "Work",
  Other = "Other",
}

export interface AddressViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  category?: AddressCategory;

  /** @format uuid */
  contactId?: string;
  country?: string | null;
  district?: string | null;
  county?: string | null;
  subCounty?: string | null;
  village?: string | null;
  parish?: string | null;
  postalCode?: string | null;
  street?: string | null;
  freeForm?: string | null;
  placeId?: string | null;
  latLon?: string | null;

  /** @format date-time */
  startDate?: string | null;

  /** @format date-time */
  endDate?: string | null;
  isPrimary?: boolean;
}

export interface BankAccountViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  bankName?: string | null;
  bankCode?: string | null;
  branchName?: string | null;
  branchCode?: string | null;
  accountName?: string | null;
  accountNumber?: string | null;
  swiftCode?: string | null;

  /** @format uuid */
  contactId?: string;
}

export enum CivilStatus {
  Single = "Single",
  Married = "Married",
  Divorced = "Divorced",
  Other = "Other",
}

export interface CompanyAdminEditModel {
  /** @format uuid */
  id?: string;

  /** @format uuid */
  contactPersonId?: string;

  /** @format uuid */
  responsibleContactId?: string;
}

export enum CompanyCategory {
  Limited = "Limited",
  Ngo = "Ngo",
  Other = "Other",
}

export interface CompanyCreateModel {
  /** @format uuid */
  id?: string | null;
  category?: CompanyCategory;
  name: string;
  email: string;
  phone: string;
  registrationNumber: string;

  /** @format date-time */
  registrationDate?: string | null;
  tinNumber?: string | null;

  /** @format int32 */
  numberOfEmployees?: number | null;

  /** @format uuid */
  contactPersonId?: string | null;

  /** @format uuid */
  accountManagerId?: string | null;
}

export interface CompanyViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  category?: CompanyCategory;
  name?: string | null;
  registrationNumber?: string | null;

  /** @format date-time */
  registrationDate?: string | null;

  /** @format int32 */
  numberOfEmployees?: number | null;

  /** @format uuid */
  contactId?: string;

  /** @format uuid */
  contactPersonId?: string | null;
  contactPerson?: ContactMiniViewModel;

  /** @format uuid */
  accountManagerId?: string | null;
  accountManager?: ContactMiniViewModel;
}

export interface ContactAdminViewModel {
  /** @format uuid */
  organizationId?: string | null;
  organization?: ContactMiniViewModel;

  /** @format uuid */
  contactPersonId?: string | null;
  contactPerson?: ContactMiniViewModel;

  /** @format uuid */
  accountManagerId?: string | null;
  accountManager?: ContactMiniViewModel;
}

export enum ContactCategory {
  Person = "Person",
  Company = "Company",
}

export interface ContactMiniViewModel {
  /** @format uuid */
  id?: string;
  name?: string | null;
  email?: string | null;
  phone?: string | null;
}

export interface ContactOrgEditModel {
  /** @format uuid */
  id?: string;

  /** @format uuid */
  organizationId?: string | null;
}

export interface ContactTagViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;

  /** @format uuid */
  contactId?: string;
  value?: string | null;
}

export enum ContactUrlCategory {
  Website = "Website",
  Facebook = "Facebook",
  Linkedin = "Linkedin",
  Twitter = "Twitter",
  Other = "Other",
}

export interface ContactUrlViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;

  /** @format uuid */
  contactId?: string;
  category?: ContactUrlCategory;
  value?: string | null;
}

export interface ContactViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  category?: ContactCategory;
  person?: PersonViewModel;
  company?: CompanyViewModel;

  /** @format uuid */
  organizationId?: string | null;
  organization?: ContactMiniViewModel;
  occasions?: EventViewModel[] | null;
  identifications?: IdentificationViewModel[] | null;
  phones?: PhoneViewModel[] | null;
  emails?: EmailViewModel[] | null;
  addresses?: AddressViewModel[] | null;
  urls?: ContactUrlViewModel[] | null;
  tags?: ContactTagViewModel[] | null;
  bankAccounts?: BankAccountViewModel[] | null;
}

export enum EmailCategory {
  Personal = "Personal",
  Work = "Work",
  Other = "Other",
}

export interface EmailViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  category?: EmailCategory;

  /** @format uuid */
  contactId?: string;
  value?: string | null;
  isPrimary?: boolean;
}

export enum EventCategory {
  Birthday = "Birthday",
  Anniversary = "Anniversary",
  Engaged = "Engaged",
  Other = "Other",
}

export interface EventViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  category?: EventCategory;
  details?: string | null;

  /** @format date-time */
  value?: string;

  /** @format uuid */
  contactId?: string;
}

export enum Gender {
  Female = "Female",
  Male = "Male",
}

export enum IdentificationCategory {
  NIN = "NIN",
  NSSF = "NSSF",
  TIN = "TIN",
  FCN = "FCN",
  Passport = "Passport",
  DrivingPermit = "DrivingPermit",
  VillageCard = "VillageCard",
  Other = "Other",
}

export interface IdentificationViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  category?: IdentificationCategory;

  /** @format uuid */
  contactId?: string;
  value?: string | null;
  issuingCountry?: string | null;

  /** @format date-time */
  issueDate?: string | null;

  /** @format date-time */
  expiryDate?: string | null;
  isPrimary?: boolean;
}

export interface NotFoundResult {
  /** @format int32 */
  statusCode?: number;
}

export interface OkResult {
  /** @format int32 */
  statusCode?: number;
}

export interface PersonCreateModel {
  /** @format uuid */
  id?: string | null;

  /** @format uuid */
  userId?: string | null;
  firstName: string;
  lastName: string;
  middleName?: string | null;
  gender?: Gender;

  /** @format date-time */
  dateOfBirth?: string | null;
  civilStatus?: CivilStatus;
  salutation?: Salutation;
  email: string;
  phone?: string | null;
  idCategory?: IdentificationCategory;
  idNumber?: string | null;

  /** @format date-time */
  idIssueDate?: string | null;

  /** @format date-time */
  idExpiryDate?: string | null;

  /** @format uuid */
  organizationId?: string | null;
  profession?: string | null;
}

export interface PersonViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;

  /** @format uuid */
  contactId?: string;
  salutation?: Salutation;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
  gender?: Gender;
  civilStatus?: CivilStatus;

  /** @format date-time */
  dateOfBirth?: string | null;
  profession?: string | null;
}

export enum PhoneCategory {
  Mobile = "Mobile",
  Office = "Office",
  Home = "Home",
  Fax = "Fax",
  Other = "Other",
}

export interface PhoneCreateModel {
  /** @format uuid */
  contactId?: string;
  category?: PhoneCategory;
  value?: string | null;
  isPrimary?: boolean;
}

export interface PhoneViewModel {
  /** @format uuid */
  id?: string;

  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;

  /** @format uuid */
  contactId?: string;
  category?: PhoneCategory;
  value?: string | null;
  isPrimary?: boolean;
}

export enum Salutation {
  Mr = "Mr",
  Mrs = "Mrs",
  Ms = "Ms",
  Dr = "Dr",
  Prof = "Prof",
  Other = "Other",
}

export interface UnauthorizedResult {
  /** @format int32 */
  statusCode?: number;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title ioTec CRM v1
 * @version v1
 * @contact The Support Team <support@iotec.io>
 *
 * The Ultimate API
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Address
     * @name CrmAddressCreate
     * @summary Create a new Address
     * @request POST:/api/crm/address
     */
    crmAddressCreate: (data: AddressViewModel, params: RequestParams = {}) =>
      this.request<AddressViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/address`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Address
     * @name CrmAddressUpdate
     * @summary Updates a Address
     * @request PUT:/api/crm/address
     */
    crmAddressUpdate: (data: AddressViewModel, params: RequestParams = {}) =>
      this.request<AddressViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/address`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Address
     * @name CrmAddressDetail
     * @summary Gets a Address by Id
     * @request GET:/api/crm/address/{id}
     */
    crmAddressDetail: (id: string, params: RequestParams = {}) =>
      this.request<AddressViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/address/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Address
     * @name CrmAddressDelete
     * @summary Deletes a Address
     * @request DELETE:/api/crm/address/{id}
     */
    crmAddressDelete: (id: string, params: RequestParams = {}) =>
      this.request<OkResult, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/address/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankAccount
     * @name CrmBankAccountCreate
     * @summary Create a new phone
     * @request POST:/api/crm/bankAccount
     */
    crmBankAccountCreate: (data: BankAccountViewModel, params: RequestParams = {}) =>
      this.request<BankAccountViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/bankAccount`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankAccount
     * @name CrmBankAccountUpdate
     * @summary Updates a BankAccount
     * @request PUT:/api/crm/bankAccount
     */
    crmBankAccountUpdate: (data: BankAccountViewModel, params: RequestParams = {}) =>
      this.request<BankAccountViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/bankAccount`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankAccount
     * @name CrmBankAccountDetail
     * @summary Gets a BankAccount by Id
     * @request GET:/api/crm/bankAccount/{id}
     */
    crmBankAccountDetail: (id: string, params: RequestParams = {}) =>
      this.request<BankAccountViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/bankAccount/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankAccount
     * @name CrmBankAccountDelete
     * @summary Deletes a BankAccount
     * @request DELETE:/api/crm/bankAccount/{id}
     */
    crmBankAccountDelete: (id: string, params: RequestParams = {}) =>
      this.request<OkResult, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/bankAccount/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CrmCompanyCreate
     * @summary Creates a new company
     * @request POST:/api/crm/company
     */
    crmCompanyCreate: (data: CompanyCreateModel, params: RequestParams = {}) =>
      this.request<ContactViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/company`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CrmCompanyList
     * @summary Search Company
     * @request GET:/api/crm/company
     */
    crmCompanyList: (
      query?: {
        ExcludeUsers?: boolean;
        ShowRecent?: boolean;
        OrganizationId?: string;
        IdList?: string[];
        Email?: string;
        UserIds?: string[];
        Phone?: string;
        IdNumber?: string;
        Profession?: string;
        Category?: ContactCategory;
        Query?: string;
        Skip?: number;
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ContactMiniViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/company`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CrmCompanyUpdate
     * @summary Update a company
     * @request PUT:/api/crm/company
     */
    crmCompanyUpdate: (data: CompanyViewModel, params: RequestParams = {}) =>
      this.request<CompanyViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/company`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name CrmContactCreate
     * @summary Creates a new person
     * @request POST:/api/crm/contact
     */
    crmContactCreate: (data: ContactViewModel, params: RequestParams = {}) =>
      this.request<ContactViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/contact`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name CrmContactList
     * @summary Searches contacts
     * @request GET:/api/crm/contact
     */
    crmContactList: (
      query?: {
        ExcludeUsers?: boolean;
        ShowRecent?: boolean;
        OrganizationId?: string;
        IdList?: string[];
        Email?: string;
        UserIds?: string[];
        Phone?: string;
        IdNumber?: string;
        Profession?: string;
        Category?: ContactCategory;
        Query?: string;
        Skip?: number;
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ContactViewModel[], NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/contact`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name CrmContactUpdate
     * @summary Updates a contact
     * @request PUT:/api/crm/contact
     */
    crmContactUpdate: (data: ContactViewModel, params: RequestParams = {}) =>
      this.request<ContactViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/contact`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name CrmContactDetail
     * @summary Gets a specific contact
     * @request GET:/api/crm/contact/{id}
     */
    crmContactDetail: (id: string, params: RequestParams = {}) =>
      this.request<ContactViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/contact/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name CrmContactDelete
     * @summary Deletes a contact
     * @request DELETE:/api/crm/contact/{id}
     */
    crmContactDelete: (id: string, params: RequestParams = {}) =>
      this.request<OkResult, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/contact/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactAdmin
     * @name CrmAdminOrgCreate
     * @summary Updates organization
     * @request POST:/api/crm/admin/org
     */
    crmAdminOrgCreate: (data: ContactOrgEditModel, params: RequestParams = {}) =>
      this.request<ContactAdminViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/admin/org`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactAdmin
     * @name CrmAdminResponsibleCreate
     * @summary Updates responsible
     * @request POST:/api/crm/admin/responsible
     */
    crmAdminResponsibleCreate: (data: CompanyAdminEditModel, params: RequestParams = {}) =>
      this.request<ContactAdminViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/admin/responsible`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactTag
     * @name CrmTagCreate
     * @summary Create a new ContactTag
     * @request POST:/api/crm/tag
     */
    crmTagCreate: (data: ContactTagViewModel, params: RequestParams = {}) =>
      this.request<ContactTagViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/tag`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactTag
     * @name CrmTagUpdate
     * @summary Updates a ContactTag
     * @request PUT:/api/crm/tag
     */
    crmTagUpdate: (data: ContactTagViewModel, params: RequestParams = {}) =>
      this.request<ContactTagViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/tag`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactTag
     * @name CrmTagDetail
     * @summary Gets a ContactTag by Id
     * @request GET:/api/crm/tag/{id}
     */
    crmTagDetail: (id: string, params: RequestParams = {}) =>
      this.request<ContactTagViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/tag/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactTag
     * @name CrmTagDelete
     * @summary Deletes a ContactTag
     * @request DELETE:/api/crm/tag/{id}
     */
    crmTagDelete: (id: string, params: RequestParams = {}) =>
      this.request<OkResult, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/tag/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactUrl
     * @name CrmUrlCreate
     * @summary Create a new ContactUrl
     * @request POST:/api/crm/url
     */
    crmUrlCreate: (data: ContactUrlViewModel, params: RequestParams = {}) =>
      this.request<ContactUrlViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/url`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactUrl
     * @name CrmUrlUpdate
     * @summary Updates a ContactUrl
     * @request PUT:/api/crm/url
     */
    crmUrlUpdate: (data: ContactUrlViewModel, params: RequestParams = {}) =>
      this.request<ContactUrlViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/url`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactUrl
     * @name CrmUrlDetail
     * @summary Gets a ContactUrl by Id
     * @request GET:/api/crm/url/{id}
     */
    crmUrlDetail: (id: string, params: RequestParams = {}) =>
      this.request<ContactUrlViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/url/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactUrl
     * @name CrmUrlDelete
     * @summary Deletes a ContactUrl
     * @request DELETE:/api/crm/url/{id}
     */
    crmUrlDelete: (id: string, params: RequestParams = {}) =>
      this.request<OkResult, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/url/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Email
     * @name CrmEmailCreate
     * @summary Create a new Email
     * @request POST:/api/crm/email
     */
    crmEmailCreate: (data: EmailViewModel, params: RequestParams = {}) =>
      this.request<EmailViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/email`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Email
     * @name CrmEmailUpdate
     * @summary Updates a Email
     * @request PUT:/api/crm/email
     */
    crmEmailUpdate: (data: EmailViewModel, params: RequestParams = {}) =>
      this.request<EmailViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/email`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Email
     * @name CrmEmailDetail
     * @summary Gets a Email by Id
     * @request GET:/api/crm/email/{id}
     */
    crmEmailDetail: (id: string, params: RequestParams = {}) =>
      this.request<EmailViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/email/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Email
     * @name CrmEmailDelete
     * @summary Deletes a Email
     * @request DELETE:/api/crm/email/{id}
     */
    crmEmailDelete: (id: string, params: RequestParams = {}) =>
      this.request<OkResult, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/email/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name CrmOccasionCreate
     * @summary Create a new Event
     * @request POST:/api/crm/occasion
     */
    crmOccasionCreate: (data: EventViewModel, params: RequestParams = {}) =>
      this.request<EventViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/occasion`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name CrmOccasionUpdate
     * @summary Updates a Event
     * @request PUT:/api/crm/occasion
     */
    crmOccasionUpdate: (data: EventViewModel, params: RequestParams = {}) =>
      this.request<EventViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/occasion`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name CrmOccasionDetail
     * @summary Gets a Event by Id
     * @request GET:/api/crm/occasion/{id}
     */
    crmOccasionDetail: (id: string, params: RequestParams = {}) =>
      this.request<EventViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/occasion/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name CrmOccasionDelete
     * @summary Deletes a Event
     * @request DELETE:/api/crm/occasion/{id}
     */
    crmOccasionDelete: (id: string, params: RequestParams = {}) =>
      this.request<OkResult, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/occasion/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Identification
     * @name CrmIdentificationCreate
     * @summary Create a new Identification
     * @request POST:/api/crm/identification
     */
    crmIdentificationCreate: (data: IdentificationViewModel, params: RequestParams = {}) =>
      this.request<IdentificationViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/identification`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Identification
     * @name CrmIdentificationUpdate
     * @summary Updates a Identification
     * @request PUT:/api/crm/identification
     */
    crmIdentificationUpdate: (data: IdentificationViewModel, params: RequestParams = {}) =>
      this.request<IdentificationViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/identification`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Identification
     * @name CrmIdentificationDetail
     * @summary Gets a Identification by Id
     * @request GET:/api/crm/identification/{id}
     */
    crmIdentificationDetail: (id: string, params: RequestParams = {}) =>
      this.request<IdentificationViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/identification/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Identification
     * @name CrmIdentificationDelete
     * @summary Deletes a Identification
     * @request DELETE:/api/crm/identification/{id}
     */
    crmIdentificationDelete: (id: string, params: RequestParams = {}) =>
      this.request<OkResult, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/identification/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Import
     * @name CrmImportFakeCreate
     * @summary Import
     * @request POST:/api/crm/import/fake/{count}
     */
    crmImportFakeCreate: (count: number, params: RequestParams = {}) =>
      this.request<OkResult, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/import/fake/${count}`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Import
     * @name CrmImportPeopleCreate
     * @summary Import
     * @request POST:/api/crm/import/people
     */
    crmImportPeopleCreate: (data: { file?: File }, params: RequestParams = {}) =>
      this.request<OkResult, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/import/people`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Import
     * @name CrmImportSampleList
     * @summary Download sample import file
     * @request GET:/api/crm/import/sample
     */
    crmImportSampleList: (params: RequestParams = {}) =>
      this.request<OkResult, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/import/sample`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name CrmPersonList
     * @summary Search people
     * @request GET:/api/crm/person
     */
    crmPersonList: (
      query?: {
        ExcludeUsers?: boolean;
        ShowRecent?: boolean;
        OrganizationId?: string;
        IdList?: string[];
        Email?: string;
        UserIds?: string[];
        Phone?: string;
        IdNumber?: string;
        Profession?: string;
        Category?: ContactCategory;
        Query?: string;
        Skip?: number;
        Limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ContactViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/person`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name CrmPersonCreate
     * @summary Creates a new person
     * @request POST:/api/crm/person
     */
    crmPersonCreate: (data: PersonCreateModel, params: RequestParams = {}) =>
      this.request<ContactViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/person`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Person
     * @name CrmPersonUpdate
     * @summary Creates a new person
     * @request PUT:/api/crm/person
     */
    crmPersonUpdate: (data: PersonViewModel, params: RequestParams = {}) =>
      this.request<ContactViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/person`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Phone
     * @name CrmPhoneCreate
     * @summary Create a new phone
     * @request POST:/api/crm/phone
     */
    crmPhoneCreate: (data: PhoneCreateModel, params: RequestParams = {}) =>
      this.request<PhoneCreateModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/phone`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Phone
     * @name CrmPhoneUpdate
     * @summary Updates a Phone
     * @request PUT:/api/crm/phone
     */
    crmPhoneUpdate: (data: PhoneViewModel, params: RequestParams = {}) =>
      this.request<PhoneViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/phone`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Phone
     * @name CrmPhoneDetail
     * @summary Gets a Phone by Id
     * @request GET:/api/crm/phone/{id}
     */
    crmPhoneDetail: (id: string, params: RequestParams = {}) =>
      this.request<PhoneViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/phone/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Phone
     * @name CrmPhoneDelete
     * @summary Deletes a Phone
     * @request DELETE:/api/crm/phone/{id}
     */
    crmPhoneDelete: (id: string, params: RequestParams = {}) =>
      this.request<OkResult, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/phone/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Register
     * @name CrmRegisterCompanyCreate
     * @summary Creates a new company
     * @request POST:/api/crm/register/company
     */
    crmRegisterCompanyCreate: (data: CompanyCreateModel, params: RequestParams = {}) =>
      this.request<ContactViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/register/company`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Register
     * @name CrmRegisterProfileList
     * @summary Load User profile
     * @request GET:/api/crm/register/profile
     */
    crmRegisterProfileList: (params: RequestParams = {}) =>
      this.request<ContactViewModel, NotFoundResult | UnauthorizedResult | void>({
        path: `/api/crm/register/profile`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
