import React, { useState, useEffect } from 'react';
import { Box, FormHelperText } from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import Loading from '../../../components/loaders/Loading';
import { ProfileLayout } from '../profile/ProfileLayout';

const SilentRenew:React.FC<any> = (props) => {
  const isMountedRef = useIsMountedRef();
  const { silentRedirectCallback } = useAuth();
  const [error, setError] = useState(null);

  useEffect(() => {
    const authCallBack = async () => {
      try {
        await silentRedirectCallback();
      } catch (err) {
        console.error(err);
        if (isMountedRef.current) {
          setError(err.message);
        }
      }
    };
    authCallBack();
  }, []);

  return (
      <ProfileLayout title="Authenticating...">
        <div {...props}>
          {error ? (
              <Box sx={{ my: 3 }}>
                <FormHelperText error>
                  {error}
                </FormHelperText>
              </Box>
          ) : (
              <Loading/>
          )}
        </div>
      </ProfileLayout>
  );
};

export default SilentRenew;
