import 'date-fns';
import React from 'react';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useTheme } from '@mui/material/styles';

interface IProps {
  onChange: (date: Date | null) => any
  value: Date | null
  label?: string
  name?: string
  variant?: 'inline'
  inputVariant?: 'standard' | 'outlined' | 'filled'
  size?: 'small' | 'medium';
  fullWidth?: boolean;
}

export default function PDateInput({ value = null, onChange, label, size, fullWidth = true, inputVariant }: IProps) {
  const theme = useTheme();

  return (
        <DesktopDatePicker
            inputFormat="dd/MM/yyyy"
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth={fullWidth}
                    label={label}
                    size={size}
                    variant={inputVariant}
                    helperText={null}
                />
            )}
            value={value}
            onChange={onChange}
            PaperProps={{
              style: {
                boxShadow: theme.shadows[10],
                borderRadius: theme.shape.borderRadius,
              }
            }}
        />
  );
}
