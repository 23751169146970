import { useQuery } from 'react-query';
import { WalletComboModel } from '../../../__generated__/apiTypes';
import { searchData } from '../../../utils/fetchHelpers';
import { remoteRoutes } from '../../../constants';
import { Currency } from '../../../__generated__/paymentTypes';


export const getDefaultWallet = (wallets: WalletComboModel[]): WalletComboModel | undefined => {
  if (!wallets)
    return undefined;
  return wallets.find((item: WalletComboModel) => item.currency === Currency.UGX) || wallets[0];
};

const useDefaultWallet = ():{ loading:boolean, wallet?:WalletComboModel } => {
  const { isLoading, data } = useQuery<WalletComboModel[], any>(
    ['default-wallet'],
    () => searchData(remoteRoutes.walletsCombo)
  );
  return { loading:isLoading, wallet:getDefaultWallet(data) };
};

export default useDefaultWallet;