import { GridProps } from '@mui/material/Grid/Grid';
import { ComboOption } from '../inputs/comboOption';

type InputProps = {
  schema?: any
  hidden?: boolean
  gridProps?: GridProps
  inputType?: InputType
  remote?: string
  options?: ComboOption[] | string[],
  defaultValue?:any
  [key: string]: any;
};

export interface IColumn {
  name: string
  label: string
  inputProps?: InputProps
  tableProps?:{
    render?: (value: any, row?: any) => any
    cellProps?: any,
    [key: string]: any
  }
}

export enum InputType {
  Number = 'Number',
  Text = 'Text',
  Select = 'Select',
  Date = 'Date',
  TextArea = 'TextArea',
  RemoteSelect = 'RemoteSelect',
  Radio = 'Radio',
  CheckBox = 'CheckBox',
  Switch = 'Switch',
}

export const isComboField = (field:IColumn) => field.inputProps?.inputType === InputType.Select || field.inputProps?.inputType === InputType.RemoteSelect;
