import React, { ChangeEvent } from 'react';
import { useField } from 'formik';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { hasValue } from './inputHelpers';
import NumericFormatCustom from '../NumericFormatCustom';

interface IProps {
  name: string
  onChangeCompleted?: () => any
}

const XNumberInput = ({ name, margin = 'normal', ...props }: TextFieldProps & IProps) => {
  const [field, meta] = useField({ name });
  const error = hasValue(meta.error) ? meta.error : undefined;
  const showError = Boolean(error && meta.touched);

  const handleChange = ({ target:{ value } }:ChangeEvent<any>) => {
    field.onChange({ target: { name, value: hasValue(value) ? value : null } });
    if (props.onChangeCompleted) {
      props.onChangeCompleted();
    }
  };

  return (
        <TextField
            {...field}
            onChange={handleChange}
            {...props}
            margin={margin}
            fullWidth
            error={showError}
            helperText={showError && error}
            value={hasValue(field.value) ? field.value : null}
            InputProps={{
              inputComponent: NumericFormatCustom as any,
            }}
        />
  );
};

export default XNumberInput;
