import type { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import React from 'react';

interface GuestGuardProps {
  children: ReactNode;
}

const GuestGuard: FC<React.PropsWithChildren<GuestGuardProps>> = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return <>{children}</>;
};


export default GuestGuard;
