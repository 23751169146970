import { isEmpty, isNumber } from 'lodash';
import { isDate } from 'date-fns';
import { ComboOption } from './comboOption';
import { camelPad } from '../../utils/stringHelpers';

export const hasNoValue = (text: any) => {
  if ( text === true || text === false) return false;
  if (isDate(text)) return false;
  if (isNumber(text)) return false;
  return isEmpty(text);
};

export const hasValue = (text: any) => !hasNoValue(text);

export const toOptions = (data: string[]): ComboOption[] => data.map((it) => ({ name: camelPad(it), id: it }));

export const comboParser = ({ id, name }: any):ComboOption => ({ id, name });
