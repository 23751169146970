import type { FC } from 'react';
import { useRef, useState } from 'react';
import { Box, Button, ButtonBase, Divider, Grid, Popover, Stack, Typography } from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
import VerifyLogo from '../../logos/VerifyLogo';
import LumenLogo from '../../logos/LumenLogo';
import MessagingLogo from '../../logos/MessagingLogo';
import { remoteRoutes } from '../../constants';
import { useTheme } from '@mui/material/styles';

const products = [
  {
    name: 'Verify',
    logo: <VerifyLogo sx={{ width: 50, height: 50, }}/>,
    url: remoteRoutes.verify
  },
  {
    name: 'Lumen',
    logo: <LumenLogo sx={{ width: 50, height: 50, }}/>,
    url: remoteRoutes.lumen
  },
  {
    name: 'Messaging',
    logo: <MessagingLogo sx={{ width: 50, height: 50, }}/>,
    url: remoteRoutes.messaging
  },
];

const AppsPopover: FC<React.PropsWithChildren<unknown>> = () => {
  const theme = useTheme();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex'
        }}
      >
        <AppsIcon />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            width: 300,
            mt: 2,
            boxShadow: theme.shadows[10],
          }
        }}
      >
        <Grid
            container
            spacing={2}
            sx={{
              p: 2
            }}
        >
            <Grid
                item
                xs={12}
            >
                <Stack>
                    <Typography
                        color="textPrimary"
                        variant="subtitle1"
                        align="center"
                    >
                        Our Products
                    </Typography>
                    <Divider sx={{ my: 2 }}/>
                </Stack>
            </Grid>
            {
                products.map((product, index) => (
                    <Grid
                        item
                        key={index}
                        xs={4}
                    >
                        <Button
                            href={product.url}
                            target="_blank"
                            variant="text"
                            sx={{
                              color: 'text.secondary',
                              p: 1
                            }}
                        >
                            <Stack
                                alignItems="center"
                            >
                                {product.logo}
                                <Box sx={{ mt: 1 }}>
                                    <Typography
                                        variant="subtitle2"
                                    >
                                        {product.name}
                                    </Typography>
                                </Box>
                            </Stack>
                        </Button>

                    </Grid>
                ))
            }
        </Grid>
      </Popover>
    </>
  );
};

export default AppsPopover;
