import React, { ChangeEvent } from 'react';
import { Autocomplete, CircularProgress, createFilterOptions, TextField } from '@mui/material';
import { useQuery } from 'react-query';
import { ComboValue, PComboProps } from './PComboInput';
import { ComboOption } from '../inputs/comboOption';
import { searchData } from '../../utils/fetchHelpers';
import { useTheme } from '@mui/material/styles';


const filter = createFilterOptions<ComboOption | string>();

const FakeProgress = () => <div style={{ height: 20, width: 20 }}>&nbsp;</div>;

const filterOptions = (options: (ComboOption | string)[], params: any) => {
  const filtered = filter(options, params) as ComboOption[];
  // Suggest the creation of a new value
  if (params.inputValue !== '') {
    filtered.push({
      id: params.inputValue,
      name: `Create "${params.inputValue}"`,
    });
  }
  return filtered;
};

export interface IPRemoteProps extends Omit<PComboProps, 'options'> {
  remote: string
  filter?: any
  searchOnline?: boolean
  parser?: (d: any) => ComboOption | string
  defaultOptions?: ComboOption[] | string[]
}

export function PRemoteSelect(props: IPRemoteProps) {
  const theme = useTheme();
  const [query, setQuery] = React.useState<string>('');
  const [inputValue, setInputValue] = React.useState('');
  const filterData = { ...props.filter, query:query, limit: 1000 };
  const { isLoading: loading, data } = useQuery<any, any>(
    [props.remote, filterData],
    () => searchData(props.remote, filterData)
  );

  const parsedData = props.parser ? data?.map(props.parser) : data;

  const options = parsedData || props.defaultOptions || [];


  const handleTouched = () => {
    if (props.onBlur)props.onBlur();
  };

  function handleChange(
    event: ChangeEvent<{}>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    value: ComboValue, _: any
  ) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    onChange(value);
  }


  function getOptionLabel(o: string | ComboOption) {
    if (typeof o === 'string') {
      return o;
    }
    if (typeof o === 'object') {
      const obj = o as ComboOption;
      return obj?.name;
    }
    return '';
  }

  const isOptionEqualToValue = (a:any, b:any)=>{
    return a === b || a?.id === b?.id || a === b?.id || a?.id === b;
  };

  const {
    onChange, onBlur,
    value, label,
    variant,
    multiple,
    helperText,
    showError,
    parser: i,
    defaultOptions,
    searchOnline,
    margin = 'normal',
    freeSolo,
    textFieldProps, defaultValue,
    disableClearable,
    ...autoProps
  } = props;
  // TODO fix this type
  const cleanValue: any = value || (multiple ? [] : null);
  const isFreeSolo = props.searchOnline ? (x) => x : freeSolo;
  const filterOpts = isFreeSolo ? filterOptions : undefined;
  return (
    <Autocomplete
      {...autoProps}
      componentsProps={{
        paper: {
          style: {
            boxShadow: theme.shadows[10],
            borderRadius: theme.shape.borderRadius,
          }
        }
      }}
      value={cleanValue}
      multiple={multiple}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        if (searchOnline) {
          setQuery(newInputValue);
        }
      }}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      //getOptionSelected={getOptionSelection}
      filterOptions={filterOpts}
      options={options}
      filterSelectedOptions={true}
      autoComplete
      loading={loading}
      freeSolo={freeSolo}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          margin={margin}
          label={label}
          fullWidth
          onBlur={handleTouched}
          error={showError}
          helperText={showError && helperText}
          variant={props.variant}
          autoComplete="off"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress
                    color="inherit"
                    size={20}
                  />
                ) : <FakeProgress />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
