import { createSvgIcon } from '@mui/material/utils';

const VerifyLogo = createSvgIcon(
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 500"
        width="100%"
        fill="currentColor"
    >
        <path fill="#1E5AE3"
              d="
M498.046875,272.032349
	C491.332123,341.108765 461.698273,397.817596 408.891876,442.046143
	C368.916046,475.528320 322.556305,494.116028 270.534515,498.094696
	C168.443436,505.902740 71.601822,452.018982 28.548637,359.987427
	C-14.598860,267.754242 -5.026991,179.045822 56.520073,97.668060
	C92.824036,49.666821 142.015137,19.814854 201.145691,8.225195
	C278.610992,-6.958091 348.819153,10.344574 409.718750,60.743938
	C461.319275,103.447601 490.391510,159.079361 497.548004,225.679184
	C499.174957,240.820160 497.962585,256.266235 498.046875,272.032349
M33.994534,310.446442
	C40.213535,326.005768 45.094501,342.270477 52.865955,357.011353
	C108.540680,462.614899 239.024628,505.673767 346.998260,454.618683
	C443.969025,408.766357 495.020477,301.196381 469.372131,196.766190
	C441.745087,84.279549 331.648499,10.778894 216.796204,28.581345
	C165.344406,36.556530 121.192352,59.504036 86.304626,98.154160
	C31.339384,159.047012 14.372818,229.975555 33.994534,310.446442
z"/>
        <path fill="#FFFFFF"
              d="
M33.922298,310.042664
	C14.372818,229.975555 31.339384,159.047012 86.304626,98.154160
	C121.192352,59.504036 165.344406,36.556530 216.796204,28.581345
	C331.648499,10.778894 441.745087,84.279549 469.372131,196.766190
	C495.020477,301.196381 443.969025,408.766357 346.998260,454.618683
	C239.024628,505.673767 108.540680,462.614899 52.865955,357.011353
	C45.094501,342.270477 40.213535,326.005768 33.922298,310.042664
M87.454849,366.028137
	C124.210144,416.390594 173.435730,444.901550 235.713364,450.177643
	C280.943512,454.009521 323.215332,443.873169 360.391113,418.120026
	C430.583160,369.495209 460.734772,301.795746 447.689850,217.295563
	C437.890900,153.821487 402.709412,105.772774 346.343384,75.465370
	C283.605957,41.732151 218.834045,41.256905 156.124146,75.408096
	C90.932877,110.910637 56.047626,167.547348 51.380733,241.625000
	C48.537426,286.756836 61.221874,328.182465 87.454849,366.028137
z"/>
        <path fill="#1E5AE3"
              d="
M87.238182,365.741180
	C61.221874,328.182465 48.537426,286.756836 51.380733,241.625000
	C56.047626,167.547348 90.932877,110.910637 156.124146,75.408096
	C218.834045,41.256905 283.605957,41.732151 346.343384,75.465370
	C402.709412,105.772774 437.890900,153.821487 447.689850,217.295563
	C460.734772,301.795746 430.583160,369.495209 360.391113,418.120026
	C323.215332,443.873169 280.943512,454.009521 235.713364,450.177643
	C173.435730,444.901550 124.210144,416.390594 87.238182,365.741180
M257.367676,76.073067
	C245.749207,76.798645 233.973450,76.559113 222.540375,78.422295
	C180.330460,85.300995 145.335770,105.460297 117.544312,137.920273
	C67.958710,195.835449 62.196327,280.649689 103.326797,344.832062
	C158.721970,431.273865 275.596832,452.408752 356.420837,390.599945
	C416.899536,344.349854 440.826477,266.940552 416.872986,195.023331
	C394.161163,126.833984 331.573303,79.890709 257.367676,76.073067
z"/>
        <path fill="#FFFFFF"
              d="
M257.848083,76.090981
	C331.573303,79.890709 394.161163,126.833984 416.872986,195.023331
	C440.826477,266.940552 416.899536,344.349854 356.420837,390.599945
	C275.596832,452.408752 158.721970,431.273865 103.326797,344.832062
	C62.196327,280.649689 67.958710,195.835449 117.544312,137.920273
	C145.335770,105.460297 180.330460,85.300995 222.540375,78.422295
	C233.973450,76.559113 245.749207,76.798645 257.848083,76.090981
M184.826004,385.800568
	C213.329651,399.152863 243.063858,403.752808 274.321716,399.155640
	C353.657257,387.487640 410.631409,311.504242 399.775818,231.538681
	C388.237885,146.546844 309.466888,88.683121 225.068985,103.202209
	C138.038986,118.174110 82.438560,206.325577 106.647575,291.537354
	C118.713577,334.007690 145.194000,364.980255 184.826004,385.800568
z"/>
        <path fill="#1E5AE3"
              d="
M184.506897,385.607178
	C145.194000,364.980255 118.713577,334.007690 106.647575,291.537354
	C82.438560,206.325577 138.038986,118.174110 225.068985,103.202209
	C309.466888,88.683121 388.237885,146.546844 399.775818,231.538681
	C410.631409,311.504242 353.657257,387.487640 274.321716,399.155640
	C243.063858,403.752808 213.329651,399.152863 184.506897,385.607178
M199.459045,156.944611
	C159.730453,180.530182 140.066605,215.497223 144.460144,261.462677
	C148.643234,305.226532 172.641495,336.986023 214.062851,351.431824
	C265.666992,369.428864 308.202148,352.834747 341.338959,309.927673
	C344.631073,311.894806 347.976013,313.893524 351.473328,315.983276
	C349.668152,319.357574 348.136047,322.221405 346.514130,325.253143
	C352.073273,328.463257 357.200592,331.424042 362.703308,334.601562
	C369.634094,322.414062 376.399200,310.517883 383.312744,298.360687
	C377.768494,295.214264 372.623169,292.294250 367.105988,289.163208
	C364.952972,292.759155 362.960205,296.087463 360.855469,299.602722
	C356.845703,297.184753 353.345886,295.074280 349.918701,293.007629
	C369.358521,244.527466 352.113586,195.898880 319.842438,168.717514
	C286.596252,140.714844 238.784409,135.819443 199.459045,156.944611
z"/>
        <path fill="#FFFFFF"
              d="
M199.785706,156.770691
	C238.784409,135.819443 286.596252,140.714844 319.842438,168.717514
	C352.113586,195.898880 369.358521,244.527466 349.918701,293.007629
	C353.345886,295.074280 356.845703,297.184753 360.855469,299.602722
	C362.960205,296.087463 364.952972,292.759155 367.105988,289.163208
	C372.623169,292.294250 377.768494,295.214264 383.312744,298.360687
	C376.399200,310.517883 369.634094,322.414062 362.703308,334.601562
	C357.200592,331.424042 352.073273,328.463257 346.514130,325.253143
	C348.136047,322.221405 349.668152,319.357574 351.473328,315.983276
	C347.976013,313.893524 344.631073,311.894806 341.338959,309.927673
	C308.202148,352.834747 265.666992,369.428864 214.062851,351.431824
	C172.641495,336.986023 148.643234,305.226532 144.460144,261.462677
	C140.066605,215.497223 159.730453,180.530182 199.785706,156.770691
M157.957779,248.556213
	C157.686462,273.582397 165.837067,295.367432 182.878448,314.213928
	C199.030350,289.221191 221.563370,275.619415 250.855011,275.682037
	C280.241302,275.744873 302.951080,289.395752 318.852020,314.189423
	C337.474274,294.486267 345.999359,268.858032 343.225830,241.760239
	C337.548004,186.286163 285.982849,148.538239 231.661377,160.090240
	C189.888794,168.973602 158.740021,205.946136 157.957779,248.556213
z"/>
        <path fill="#1E5AE3"
              d="
M157.951965,248.071976
	C158.740021,205.946136 189.888794,168.973602 231.661377,160.090240
	C285.982849,148.538239 337.548004,186.286163 343.225830,241.760239
	C345.999359,268.858032 337.474274,294.486267 318.852020,314.189423
	C302.951080,289.395752 280.241302,275.744873 250.855011,275.682037
	C221.563370,275.619415 199.030350,289.221191 182.878448,314.213928
	C165.837067,295.367432 157.686462,273.582397 157.951965,248.071976
M216.680496,192.229675
	C201.492554,212.518021 205.044937,239.298370 224.914581,254.303894
	C239.139084,265.046204 258.335541,265.839233 274.238068,256.341583
	C288.484436,247.832977 296.841156,229.811584 294.191376,213.047546
	C291.312622,194.834595 280.891541,182.356720 263.453644,177.342056
	C245.471619,172.170944 229.589417,177.263916 216.680496,192.229675
z"/>
        <path fill="#FFFFFF"
              d="
M216.894775,191.941666
	C229.589417,177.263916 245.471619,172.170944 263.453644,177.342056
	C280.891541,182.356720 291.312622,194.834595 294.191376,213.047546
	C296.841156,229.811584 288.484436,247.832977 274.238068,256.341583
	C258.335541,265.839233 239.139084,265.046204 224.914581,254.303894
	C205.044937,239.298370 201.492554,212.518021 216.894775,191.941666
z"/>
    </svg>,
    'VerifyLogo'
);

export default VerifyLogo;
