import React, { useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';

import { Grid, Alert } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { hasValue } from '../inputs/inputHelpers';
import CodeView from '../CodeView';
import { LoadingButton } from '@mui/lab';

interface IProps {
  schema?: any
  fullSubmitButton?: boolean
  onSubmit: (values: any, actions: FormikHelpers<any>) => any
  onCancel?: () => any
  onDelete?: () => any
  isDeletable?: boolean
  debug?: boolean
  loading?: boolean
  children?: React.ReactNode
  initialValues?: any
  showFormErrors?: any
  submitButtonLabel?: string

}

const XForm = (props: IProps) => {
  const [count, setCount] = useState<number>(0);

  function handleDelete(e) {
    e.preventDefault();
    if (count === 1) {
      setCount(0);
      if (props.onDelete) {
        props.onDelete();
      }
    } else {
      setCount(count + 1);
    }
  }

  const onCancel = (e) => {
    e.preventDefault();
    props.onCancel();
  };

  return (
    <Formik
      initialValues={props.initialValues || {}}
      onSubmit={props.onSubmit}
      validationSchema={props.schema}
      validateOnBlur
      enableReinitialize={false}
    >
      {({ submitForm, isSubmitting, values, errors, touched }) => (
        <Form>
          <Grid
            container
            spacing={0}
          >
            {
                    hasValue(errors) && props.showFormErrors
                    && (
                    <Grid
                      item
                      xs={12}
                    >
                      <Box
                        display="flex"
                        pb={1}
                        justifyContent="center"
                      >
                        <Alert severity="warning">Please provide all required fields(s)</Alert>
                      </Box>
                    </Grid>
                    )
                }
            <Grid
              item
              xs={12}
            >
              {props.children}
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Box
                py={2}
              >
                {
                  props.fullSubmitButton
                    ?
                    <Grid
                      container
                      spacing={1}
                    >
                      <Grid
                        item
                        xs={12}
                      >
                        <Button
                          fullWidth={true}
                          size="large"
                          variant="contained"
                          color="primary"
                          onClick={submitForm}
                          disabled={isSubmitting || props.loading}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                    :
                    <Grid
                      container
                      spacing={1}
                      justifyContent="flex-end"
                    >
                      {
                        props.onDelete && props?.isDeletable
                        && (
                          <Grid item>
                            <Button
                              variant="text"
                              color="secondary"
                              onClick={handleDelete}
                              disabled={isSubmitting}
                            >
                              {count === 1 ? '! Confirm' : 'Delete'}
                            </Button>
                          </Grid>
                        )
                      }
                      {
                        props.onCancel
                        && (
                          <Grid item>
                            <Button
                              variant="text"
                              color="primary"
                              onClick={onCancel}
                              disabled={isSubmitting || props.loading}
                            >
                              Cancel
                            </Button>
                          </Grid>
                        )
                      }
                      <Grid item>
                        <LoadingButton
                          variant="contained"
                          color="primary"
                          onClick={submitForm}
                          loading={isSubmitting || props.loading}
                          disabled={isSubmitting || props.loading}
                        >
                          {props.submitButtonLabel ?? 'Submit'}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                }
              </Box>
            </Grid>
            {
                    props.debug
                    && (
                    <Grid
                      item
                      xs={12}
                    >
                      <CodeView data={{ values, errors, touched }} />
                    </Grid>
                    )
                }
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default XForm;
