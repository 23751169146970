import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import PayLogo from '../../../logos/PayLogo';

type Props = {
  title?:string
};
export const ProfileLayout:FC<React.PropsWithChildren<Props>>  = ({ children, title = 'Complete your profile' }) => (
  <Box>
    <Container
      component="main"
      maxWidth="sm"
    >
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          pt: 15
        }}
      >
          <PayLogo
              sx={{
                width: 80,
                height: 80,
              }}
          />
        <Typography
          component="h1"
          variant="h6"
          sx={{ mt: 3 }}
        >
          {title}
        </Typography>
        <Box sx={{ mt: 5 }}>
          {children}
        </Box>
      </Box>
    </Container>

  </Box>
);
