import React from 'react';
import { useField } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

interface IProps {
  name: string
  label: string
}

const XCheckBoxInput = (props: IProps) => {
  const { name, label } = props;
  const [field] = useField({ name });

  return (
    <FormControlLabel
      label={label}
      control={(
        <Checkbox
          checked={Boolean(field.value)}
          value={Boolean(field.value)}
          onChange={field.onChange}
          onBlur={field.onBlur}
          name={name}
          color="primary"
        />
          )}
    />
  );
};
export default XCheckBoxInput;
