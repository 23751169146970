/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AirtelBalanceRequest {
  type?: string | null;
  bProvider?: string | null;
  payId1?: string | null;
  payId2?: string | null;
  payId?: string | null;
  pin?: string | null;
  language?: string | null;
  provider?: string | null;
  msisdn?: string | null;
  username?: string | null;
  password?: string | null;
}

export interface AirtelMerchCashInRequest {
  sndinstrument?: string | null;
  msisdn?: string | null;
  mobTxnId?: string | null;
  payid?: string | null;
  serviceType?: string | null;
  referenceNo?: string | null;
  reference?: string | null;
  extTrId?: string | null;
  billerCode?: string | null;
  payerMsisdn?: string | null;
  payerAmount?: string | null;
  sndprovider?: string | null;
  language?: string | null;
  rcvinstrument?: string | null;
  language2?: string | null;
  payid2?: string | null;
  language1?: string | null;
  payid1?: string | null;
  provider1?: string | null;
  pin?: string | null;
  provider2?: string | null;
  amount?: string | null;
  msisdn2?: string | null;
  rcvprovider?: string | null;
  isMerchantCashin?: string | null;
  merchantTxnId?: string | null;
  provider?: string | null;
  bprovider?: string | null;
  pinCheck?: string | null;
  type?: string | null;
  interfaceId?: string | null;
  username?: string | null;
  password?: string | null;
}

export interface AirtelMerchPayCallback {
  type?: string | null;
  txnId?: string | null;
  exttrId?: string | null;
  txnStatus?: string | null;
  message?: string | null;
}

export interface AirtelMerchPayRequest {
  interfaceId?: string | null;
  msisdn?: string | null;
  msisdn2?: string | null;
  amount?: string | null;
  exttrId?: string | null;
  reference?: string | null;
  billerId?: string | null;
  memo?: string | null;
  serviceType?: string | null;
  username?: string | null;
  password?: string | null;
}

export interface AirtelStanPayCallbackRequest {
  sndinstrument?: string | null;
  msisdn?: string | null;
  mobTxnId?: string | null;
  payid?: string | null;
  serviceType?: string | null;
  referenceNo?: string | null;
  reference?: string | null;
  externalTransactionId?: string | null;
  billerCode?: string | null;
  sndprovider?: string | null;
  language?: string | null;
  rcvinstrument?: string | null;
  language2?: string | null;
  payid2?: string | null;
  language1?: string | null;
  payid1?: string | null;
  provider1?: string | null;
  pin?: string | null;
  provider2?: string | null;
  amount?: string | null;
  msisdn2?: string | null;
  rcvprovider?: string | null;
  isMerchantCashin?: string | null;
  merchantTxnId?: string | null;
  provider?: string | null;
  bprovider?: string | null;
  pinCheck?: string | null;
  type?: string | null;
  interfaceId?: string | null;
  username?: string | null;
  password?: string | null;
}

export interface AirtelStatusRequest {
  type?: string | null;
  interfaceId?: string | null;
  exttrid?: string | null;
  trid?: string | null;
}

export interface Bank {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  lastUpdated?: string | null;
  isDeleted?: boolean;
  /** @maxLength 40 */
  createdBy?: string | null;
  /** @maxLength 250 */
  name?: string | null;
  /** @maxLength 40 */
  bankIdentificationCode?: string | null;
  /** @maxLength 40 */
  stanbicIdentificationCode?: string | null;
  /** @maxLength 40 */
  mainBranchCode?: string | null;
  /** @maxLength 250 */
  mainBranchName?: string | null;
  /** @maxLength 50 */
  accountNumberRegex?: string | null;
}

export interface BankBeneficiaryCreateModel {
  /** @minLength 1 */
  name: string;
  /** @format uuid */
  bankId?: string;
  /** @minLength 1 */
  accountNumber: string;
  accountCurrency: Currency;
}

export interface BankBeneficiaryUpdateModel {
  /** @format uuid */
  beneficiaryId?: string;
  /** @minLength 1 */
  name: string;
  /** @format uuid */
  bankId?: string;
  /** @minLength 1 */
  accountNumber: string;
  accountCurrency?: Currency;
}

export interface BankComboModel {
  /** @format uuid */
  id?: string;
  name?: string | null;
  code?: string | null;
}

export interface BankCreateModel {
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  bankIdentificationCode: string;
  stanbicIdentificationCode?: string | null;
  mainBranchCode?: string | null;
  mainBranchName?: string | null;
  accountNumberRegex?: string | null;
}

export interface BankDepositNotification {
  /**
   * A reference to identify the ioTec wallet
   * @minLength 1
   */
  walletCode: string;
  /** @format double */
  amount: number;
  /** @minLength 1 */
  transactionId: string;
  /** @format date-time */
  transactionDate: string;
  depositorName?: string | null;
  memo?: string | null;
  depositorPhoneNumber?: string | null;
  depositorEmail?: string | null;
}

export interface BankDisbursementRequest {
  /**
   * The reference BankID from the ioTec platform, set this if you do not have the Bank Identification Code
   * @format uuid
   */
  bankId?: string | null;
  /** The Bank Identification Code, also known as swift code */
  bankIdentificationCode?: string | null;
  transferType?: BankTransferType;
  currency?: Currency;
  /**
   * The wallet to be used in the transaction
   * @format uuid
   */
  walletId: string;
  /** A reference to the transaction used by an external system for reconciliation and is not required to be unique. */
  externalId?: string | null;
  /**
   * The bank account name
   * @minLength 1
   */
  accountName: string;
  /**
   * The recipient account, this should be a valid account number
   * @minLength 1
   */
  accountNumber: string;
  /**
   * Amount that will be debited from the payer account.
   * @format double
   */
  amount: number;
  /**
   * A message to be included for payee's reference, in this case, the recipient.
   * @maxLength 100
   */
  payeeNote?: string | null;
  /** An optional chanel to be used for the transaction */
  channel?: string | null;
}

export interface BankTransferNotification {
  transactionId?: string | null;
  externalTransactionId?: string | null;
  /** @format date-time */
  transactionDate?: string;
  status?: string | null;
  statusMessage?: string | null;
}

export enum BankTransferType {
  InternalTransfer = "InternalTransfer",
  Rtgs = "Rtgs",
  Eft = "Eft",
  Swift = "Swift",
}

export interface BankViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  name?: string | null;
  bankIdentificationCode?: string | null;
  stanbicIdentificationCode?: string | null;
  mainBranchCode?: string | null;
  mainBranchName?: string | null;
  accountNumberRegex?: string | null;
}

export interface BankWalletValidationRequest {
  /**
   * A reference to identify the ioTec wallet
   * @minLength 1
   */
  walletCode: string;
}

export interface BankWalletValidationResponse {
  /**
   * A reference to identify the ioTec wallet
   * @minLength 1
   */
  walletCode: string;
  walletName?: string | null;
  /** @format date-time */
  createdAt?: string;
  isActive?: boolean;
  currency?: Currency;
}

export interface BaseApproveModel {
  /** @format uuid */
  id?: string;
  /** @minLength 1 */
  remarks: string;
  decision?: boolean;
}

export interface BaseDeleteModel {
  /** @format uuid */
  id?: string;
  /** @minLength 1 */
  remarks: string;
}

export interface BaseResponse {
  message?: string | null;
  status?: boolean;
}

export enum BeneficiaryAccountCategory {
  Wallet = "Wallet",
  PhoneNumber = "PhoneNumber",
  BankAccount = "BankAccount",
}

export interface BeneficiaryBankViewModel {
  /** @format uuid */
  bankId?: string;
  bankData?: BankViewModel;
  accountNumber?: string | null;
  accountCurrency?: Currency;
}

export interface BeneficiaryComboModel {
  id?: string | null;
  name?: string | null;
  category?: BeneficiaryAccountCategory;
  phoneNumber?: string | null;
  walletCode?: string | null;
  bankAccount?: BeneficiaryBankViewModel;
}

export interface BeneficiaryViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format uuid */
  partnerId?: string;
  partner?: ComboModel;
  name?: string | null;
  category?: BeneficiaryAccountCategory;
  phoneNumber?: string | null;
  walletCode?: string | null;
  bankAccount?: BeneficiaryBankViewModel;
  /** @format uuid */
  createdById?: string;
  createdByData?: ComboModel;
}

export interface BulkDisbursementApprovalRequest {
  /** @format uuid */
  bulkId?: string;
  remarks?: string | null;
}

export interface BulkDisbursementApprovalViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format uuid */
  bulkId?: string;
  /** @format int32 */
  order?: number;
  role?: string | null;
  /** @format uuid */
  approvedBy?: string | null;
  approvedByData?: ComboModel;
  /** @format date-time */
  approvedOn?: string | null;
  remarks?: string | null;
}

export interface BulkDisbursementImportRequest {
  paymentCategory?: PaymentCategory;
  /**
   * The wallet to be used in the transaction
   * @format uuid
   */
  walletId?: string;
  /**
   * The wallet to be used in the transaction
   * @format uuid
   */
  docId?: string;
  externalReference?: string | null;
  payeeNote?: string | null;
}

export interface BulkDisbursementPreview {
  /** @format uuid */
  docId?: string;
  paymentCategory?: PaymentCategory;
  document?: DocViewModel;
  /** @format int32 */
  totalRecords?: number;
  /** @format double */
  totalAmount?: number;
  distribution?: VendorPortion[] | null;
  errors?: ImportError[] | null;
  sample?: DisbursementViewModel[] | null;
}

export enum BulkDisbursementStatus {
  Pending = "Pending",
  Processing = "Processing",
  Completed = "Completed",
  Error = "Error",
  Rejected = "Rejected",
}

export interface BulkDisbursementViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  status?: BulkDisbursementStatus;
  /** @format uuid */
  walletId?: string;
  wallet?: ComboModel;
  /** @format uuid */
  partnerId?: string;
  currency?: Currency;
  /** @format double */
  totalAmount?: number;
  /** @format double */
  totalCharges?: number;
  /** @format int32 */
  totalRequests?: number;
  /** @format int32 */
  completedRequests?: number;
  /** @format int32 */
  failedRequests?: number;
  /** @format date-time */
  startedAt?: string | null;
  /** @format date-time */
  completedAt?: string | null;
  externalTransactionId?: string | null;
  /** @format uuid */
  documentId?: string | null;
  document?: DocViewModel;
  createdBy?: string | null;
  createdByData?: ComboModel;
  message?: string | null;
  /** @format uuid */
  rejectedBy?: string | null;
  rejectedByData?: ComboModel;
  /** @format date-time */
  rejectedAt?: string | null;
  rejectedReason?: string | null;
  approvals?: BulkDisbursementApprovalViewModel[] | null;
}

export interface BulkNameVerificationRequest {
  vendor?: Vendor;
  /**
   * Wallet to be verified
   * @format uuid
   */
  walletId?: string | null;
  /**
   * The number of records to verify
   * @format int32
   */
  limit?: number;
  /**
   * Created after date
   * @format date-time
   */
  from?: string | null;
  /**
   * Created before date
   * @format date-time
   */
  to?: string | null;
  /** If true, only collection records will be verified, otherwise only disbursement records will be verified */
  collections?: boolean | null;
}

export interface CollectionChargeViewModel {
  /**
   * Amount that will be debited from the payer account.
   * @format double
   */
  amount?: number;
  /** @format double */
  vendorCharge?: number;
  /** @format double */
  transactionCharge?: number;
  /**
   * The total transaction charge
   * @format double
   */
  totalTransactionCharge?: number;
}

export interface CollectionData {
  /** @format double */
  totalMtn?: number;
  /** @format double */
  totalAirtel?: number;
  /** @format double */
  totalMock?: number;
}

export interface CollectionPreviewModel {
  vendor?: Vendor;
  /** The payers account, this should be a valid MSISDN */
  payer?: string | null;
  /** The payer's name */
  payerName?: string | null;
  /**
   * Amount that will be debited from the payer account.
   * @format double
   */
  amount?: number;
  /** The charging model applied to this transaction */
  chargeModel?: string | null;
  /**
   * The ioTec transaction charge
   * @format double
   */
  transactionCharge?: number;
  /**
   * The Vendor transaction charge
   * @format double
   */
  vendorCharge?: number;
  /**
   * The total transaction charge
   * @format double
   */
  totalTransactionCharge?: number;
  /** @format double */
  customerBill?: number;
  extendedCharges?: CollectionChargeViewModel;
}

export interface CollectionRequest {
  category?: PaymentCategory;
  currency?: Currency;
  /**
   * The wallet to be used in the transaction
   * @format uuid
   */
  walletId?: string;
  /** A reference to the transaction used by an external system for reconciliation and is not required to be unique. */
  externalId?: string | null;
  /**
   * The payer account, this should be an MSISDN
   * @minLength 1
   */
  payer: string;
  /**
   * A message to be included for the payer's reference to this transaction.
   * @maxLength 100
   */
  payerNote?: string | null;
  /**
   * Amount that will be debited from the payer account.
   * @format double
   */
  amount: number;
  /**
   * A message to be included for payee's reference, in this case, the API user.
   * @maxLength 100
   */
  payeeNote?: string | null;
  /** An optional chanel to be used for the transaction */
  channel?: string | null;
  transactionChargesCategory?: TransactionChargesCategory;
}

export interface CollectionViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  category?: PaymentCategory;
  status?: RequestStatus;
  paymentChannel?: PaymentChannel;
  /** Machine friendly status code */
  statusCode?: string | null;
  /** More details on  the status received */
  statusMessage?: string | null;
  /** A reference to the transaction used by an external system for reconciliation and is not required to be unique. */
  externalId?: string | null;
  /**
   * Amount that will be debited from the payer account.
   * @format double
   */
  amount?: number;
  /** A message to be included for the payer's reference to this transaction. */
  payerNote?: string | null;
  /** A message to be included for payee's reference, in this case, the recipient. */
  payeeNote?: string | null;
  currency?: Currency;
  wallet?: ComboModel;
  /** The charging model applied to this transaction */
  chargeModel?: string | null;
  /** The user id that created this transaction */
  createdBy?: string | null;
  /**
   * The ioTec transaction charge
   * @format double
   */
  transactionCharge?: number;
  /**
   * The Vendor transaction charge
   * @format double
   */
  vendorCharge?: number;
  /** @format double */
  totalTransactionCharge?: number;
  vendor?: Vendor;
  /** The ID tracked by the vendor */
  vendorTransactionId?: string | null;
  /**
   * The date of reconciliation
   * @format date-time
   */
  reconciledOn?: string | null;
  transactions?: WalletTransactionViewModel[] | null;
  /** The recipient account, this should be a valid MSISDN */
  payer?: string | null;
  /** The payer's name */
  payerName?: string | null;
}

export interface CollectionViewModelPaginatedListViewModel {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  total?: number;
  data?: CollectionViewModel[] | null;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
}

export interface CollectionsSummary {
  /** @format double */
  walletBalance?: number;
  currency?: Currency;
  /** @format double */
  totalCollected?: number;
  /** @format date-time */
  from?: string;
  /** @format date-time */
  to?: string;
}

export interface CollectionsWalletStats {
  data?: CollectionViewModel[] | null;
  /** @format double */
  walletBalance?: number;
  currency?: Currency;
  /** @format double */
  totalCollections?: number;
  /** @format double */
  totalFailed?: number;
  collectionData?: CollectionData;
  totalsPerMonth?: TotalInMonth[] | null;
  walletName?: string | null;
}

export interface ComboModel {
  id?: string | null;
  name?: string | null;
}

export enum CompanyCategory {
  PrivateLimited = "PrivateLimited",
  PublicLimited = "PublicLimited",
  SoleProprietorship = "SoleProprietorship",
  Partnership = "Partnership",
  Other = "Other",
}

export interface ContactMiniViewModel {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  contactPersonId?: string | null;
  name: string | null;
  email?: string | null;
  phone?: string | null;
}

export interface CreateProfileRequest {
  /** @format uuid */
  id?: string | null;
  /** @minLength 1 */
  firstName: string;
  /** @minLength 1 */
  lastName: string;
  middleName?: string | null;
  gender?: Gender;
  /** @format date */
  dateOfBirth?: string | null;
  civilStatus?: string | null;
  salutation?: string | null;
  /** @minLength 1 */
  email: string;
  phone?: string | null;
}

export enum Currency {
  ITX = "ITX",
  UGX = "UGX",
}

export interface DisbursementApprovalDecision {
  /** @format uuid */
  disbursementId?: string;
  /** The decision made by the user, true for approve and false for reject */
  decision?: boolean;
  /** @maxLength 100 */
  remarks?: string | null;
}

export interface DisbursementData {
  /** @format double */
  totalMtn?: number;
  /** @format double */
  totalAirtel?: number;
  /** @format double */
  totalMock?: number;
}

export interface DisbursementPreviewModel {
  vendor?: Vendor;
  /** The recipient account, this should be a valid MSISDN */
  payee?: string | null;
  /** The recipient name */
  payeeName?: string | null;
  /**
   * Amount that will be debited from the payer account.
   * @format double
   */
  amount?: number;
  /** The charging model applied to this transaction */
  chargeModel?: string | null;
  /**
   * The ioTec transaction charge
   * @format double
   */
  transactionCharge?: number;
  /**
   * The Vendor transaction charge
   * @format double
   */
  vendorCharge?: number;
  /**
   * The total transaction charge
   * @format double
   */
  totalTransactionCharge?: number;
}

export interface DisbursementRequest {
  category?: PaymentCategory;
  currency?: Currency;
  /**
   * The wallet to be used in the transaction
   * @format uuid
   */
  walletId?: string;
  /** A reference to the transaction used by an external system for reconciliation and is not required to be unique. */
  externalId?: string | null;
  /** The name if the payee */
  payeeName?: string | null;
  /** An email to be notified on disbursement */
  payeeEmail?: string | null;
  /**
   * The recipient account, this should be a valid MSISDN
   * @minLength 1
   */
  payee: string;
  /**
   * Amount that will be debited from the payer account.
   * @format double
   */
  amount: number;
  /**
   * A message to be included for the payer's reference to this transaction.
   * @maxLength 100
   */
  payerNote?: string | null;
  /**
   * A message to be included for payee's reference, in this case, the recipient.
   * @maxLength 100
   */
  payeeNote?: string | null;
  /** An optional chanel to be used for the transaction */
  channel?: string | null;
  /**
   * The reference BankID from the ioTec platform, set this if you do not have the Bank Identification Code
   * @format uuid
   */
  bankId?: string | null;
  /** The Bank Identification Code, also known as swift code */
  bankIdentificationCode?: string | null;
  bankTransferType?: BankTransferType;
}

export interface DisbursementViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  category?: PaymentCategory;
  status?: RequestStatus;
  paymentChannel?: PaymentChannel;
  /** Machine friendly status code */
  statusCode?: string | null;
  /** More details on  the status received */
  statusMessage?: string | null;
  /** A reference to the transaction used by an external system for reconciliation and is not required to be unique. */
  externalId?: string | null;
  /**
   * Amount that will be debited from the payer account.
   * @format double
   */
  amount?: number;
  /** A message to be included for the payer's reference to this transaction. */
  payerNote?: string | null;
  /** A message to be included for payee's reference, in this case, the recipient. */
  payeeNote?: string | null;
  currency?: Currency;
  wallet?: ComboModel;
  /** The charging model applied to this transaction */
  chargeModel?: string | null;
  /** The user id that created this transaction */
  createdBy?: string | null;
  /**
   * The ioTec transaction charge
   * @format double
   */
  transactionCharge?: number;
  /**
   * The Vendor transaction charge
   * @format double
   */
  vendorCharge?: number;
  /** @format double */
  totalTransactionCharge?: number;
  vendor?: Vendor;
  /** The ID tracked by the vendor */
  vendorTransactionId?: string | null;
  /**
   * The date of reconciliation
   * @format date-time
   */
  reconciledOn?: string | null;
  transactions?: WalletTransactionViewModel[] | null;
  /** The recipient account, this should be a valid MSISDN */
  payee?: string | null;
  /** The recipient name */
  payeeName?: string | null;
  /** The recipient name status */
  payeeNameVerified?: boolean | null;
  /**
   * The ID of the bulk transaction
   * @format uuid
   */
  bulkId?: string | null;
  /** The internal transaction id generated by this system, in case the vendor does support guid */
  internalRequestId?: string | null;
  /**
   * Only for bank transfers, the bank account to use
   * @format uuid
   */
  bankId?: string | null;
  bank?: BankViewModel;
  bankTransferType?: BankTransferType;
}

export interface DisbursementViewModelPaginatedListViewModel {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  total?: number;
  data?: DisbursementViewModel[] | null;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
}

export interface DisbursementWalletStats {
  data?: DisbursementViewModel[] | null;
  /** @format double */
  walletBalance?: number;
  walletName?: string | null;
  currency?: Currency;
  /** @format double */
  totalDisbursement?: number;
  /** @format double */
  totalFailed?: number;
  disbursementData?: DisbursementData;
  totalsPerMonth?: TotalInMonth[] | null;
}

export interface DisbursementsSummary {
  /** @format double */
  walletBalance?: number;
  currency?: Currency;
  /** @format double */
  totalDisbursed?: number;
  /** @format date-time */
  from?: string;
  /** @format date-time */
  to?: string;
}

export interface DocViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  name?: string | null;
  contentType?: string | null;
  originalName?: string | null;
  description?: string | null;
  size?: string | null;
  /** @format uuid */
  partnerId?: string;
}

export enum Gender {
  Male = "Male",
  Female = "Female",
}

export enum HttpVerb {
  Post = "Post",
  Get = "Get",
}

export interface ImportError {
  /** @format int32 */
  row?: number;
  /** @format int32 */
  col?: number;
  message?: string | null;
}

export interface JsonNode {
  options?: JsonNodeOptions;
  parent?: JsonNode;
  root?: JsonNode;
}

export interface JsonNodeOptions {
  propertyNameCaseInsensitive?: boolean;
}

export interface LiquidationBankAccountCreateModel {
  accountNumber?: string | null;
  accountName?: string | null;
  accountCurrency?: Currency;
  /** @format uuid */
  walletId?: string;
  /** @format uuid */
  bankId?: string;
}

export interface LiquidationBankAccountUpdateModel {
  accountNumber?: string | null;
  accountName?: string | null;
  accountCurrency?: Currency;
  /** @format uuid */
  walletId?: string;
  /** @format uuid */
  bankId?: string;
}

export interface LiquidationBankAccountViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  accountNumber?: string | null;
  accountName?: string | null;
  accountCurrency?: Currency;
  createdBy?: string | null;
  createdByData?: ComboModel;
  /** @format uuid */
  walletId?: string;
  wallet?: ComboModel;
  /** @format uuid */
  bankId?: string;
  bank?: BankViewModel;
  /** @format uuid */
  partnerId?: string;
  partner?: ComboModel;
  approvedBy?: string | null;
  approvedByData?: ComboModel;
  /** @format date-time */
  approvedAt?: string | null;
  approvedRemarks?: string | null;
}

export enum LiquidationCategory {
  Manual = "Manual",
  BankDeposit = "BankDeposit",
}

export interface LiquidationCreateModel {
  category?: LiquidationCategory;
  transferType?: BankTransferType;
  /** @format uuid */
  walletId?: string;
  /** @format double */
  amount?: number;
  /** @minLength 1 */
  referenceNumber: string;
  /** @format uuid */
  transactionId?: string | null;
}

export enum LiquidationStatus {
  Pending = "Pending",
  Processing = "Processing",
  Success = "Success",
  Failed = "Failed",
}

export interface LiquidationViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  category?: LiquidationCategory;
  createdBy?: string | null;
  createdByData?: ComboModel;
  status?: LiquidationStatus;
  /** @format uuid */
  walletId?: string;
  wallet?: ComboModel;
  /** @format uuid */
  accountId?: string;
  account?: LiquidationBankAccountViewModel;
  /** @format uuid */
  partnerId?: string;
  partner?: ComboModel;
  /** @format double */
  amount?: number;
  vendorTransactionId?: string | null;
  internalRequestId?: string | null;
  memo?: string | null;
  referenceNumber?: string | null;
  /** @format date-time */
  startedAt?: string | null;
  /** @format date-time */
  completedAt?: string | null;
}

export interface MessageTemplateData {
  /** @format uuid */
  id?: string;
  body?: string | null;
  subject?: string | null;
}

export interface NameVerificationRequest {
  /**
   * @minLength 1
   * @pattern [0-9]{10,}
   */
  accountId: string;
  bankIdentificationCode?: string | null;
  reference?: string | null;
}

export enum NameVerificationStatus {
  Pending = "Pending",
  Success = "Success",
  Failed = "Failed",
  NotFound = "NotFound",
  Barred = "Barred",
}

export interface NameVerificationViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  accountId?: string | null;
  name?: string | null;
  vendor?: Vendor;
  status?: NameVerificationStatus;
}

export interface NotFoundResult {
  /** @format int32 */
  statusCode?: number;
}

export interface OkResult {
  /** @format int32 */
  statusCode?: number;
}

export interface PartnerCreateModel {
  /** @format uuid */
  id?: string;
  /** @minLength 1 */
  clientId: string;
  partnerIps: string[];
  /**
   * @format email
   * @minLength 1
   */
  email: string;
  /**
   * @format tel
   * @minLength 1
   */
  phone: string;
  /** @maxLength 12 */
  shortName?: string | null;
  category: CompanyCategory;
  /** @minLength 1 */
  name: string;
  tinNumber?: string | null;
  /** @minLength 1 */
  registrationNumber: string;
  /** @format date */
  registrationDate?: string | null;
  /** @format int32 */
  numberOfEmployees?: number | null;
}

export interface PartnerIpViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  /** @minLength 1 */
  ipAddress: string;
}

export interface PartnerLinkModel {
  /** @format uuid */
  id?: string;
  /** @minLength 1 */
  clientId: string;
  partnerIps: string[];
}

export interface PartnerMemberEditModel {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  partnerId?: string;
  active?: boolean;
  roles?: string[] | null;
}

export interface PartnerMemberInvitationCreateModel {
  /** @format uuid */
  partnerId?: string;
  /** @minLength 1 */
  email: string;
}

export interface PartnerMemberViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  partner?: ComboModel;
  person?: ContactMiniViewModel;
  active?: boolean;
  /** @format uuid */
  partnerId?: string;
  roles?: string[] | null;
}

export interface PartnerNameUpdateModel {
  /** @format uuid */
  id?: string;
  /** @maxLength 12 */
  shortName?: string | null;
}

export interface PartnerViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  clientId?: string | null;
  name?: string | null;
  shortName?: string | null;
  active?: boolean;
  company?: ContactMiniViewModel;
  members?: PartnerMemberViewModel[] | null;
  partnerIps?: PartnerIpViewModel[] | null;
  wallets?: WalletViewModel[] | null;
}

export enum PaymentCategory {
  MobileMoney = "MobileMoney",
  WalletToWallet = "WalletToWallet",
  BankTransfer = "BankTransfer",
}

export enum PaymentChannel {
  Api = "Api",
  Portal = "Portal",
  Link = "Link",
  Bulk = "Bulk",
  Woocommerce = "Woocommerce",
}

export enum PaymentOperation {
  Disbursement = "Disbursement",
  Collection = "Collection",
}

export interface PhoneBeneficiaryCreateModel {
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  phoneNumber: string;
}

export interface PhoneBeneficiaryUpdateModel {
  /** @format uuid */
  beneficiaryId?: string;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  phoneNumber: string;
}

export interface PriceTier {
  /** @format int32 */
  id?: number;
  /** @format double */
  lowerLimit?: number;
  /** @format double */
  upperLimit?: number;
  charges?: string | null;
  vendor?: Vendor;
}

export interface PriceTierCreateModel {
  rootPartner?: boolean;
  /** @format uuid */
  walletId?: string;
  /** @format double */
  lowerLimit?: number;
  /** @format double */
  upperLimit?: number;
  charges?: string | null;
  vendor?: Vendor;
  operation?: PaymentOperation;
}

export interface PriceTierUpdateModel {
  rootPartner?: boolean;
  /** @format uuid */
  walletId?: string;
  /** @format double */
  lowerLimit?: number;
  /** @format double */
  upperLimit?: number;
  charges?: string | null;
  vendor?: Vendor;
  operation?: PaymentOperation;
  /** @format int32 */
  id?: number;
}

export enum RequestStatus {
  Pending = "Pending",
  SentToVendor = "SentToVendor",
  Success = "Success",
  Failed = "Failed",
  AwaitingApproval = "AwaitingApproval",
}

export interface RtgsPaymentRequest {
  currency?: Currency;
  /**
   * The wallet to be used in the transaction
   * @format uuid
   */
  walletId?: string;
  /** A reference to the transaction used by an external system for reconciliation and is not required to be unique. */
  externalId?: string | null;
  /**
   * Amount that will be debited from the payer account.
   * @format double
   */
  amount: number;
  /** A message to be included for the payer's reference to this transaction. */
  payerNote?: string | null;
  /** An optional chanel to be used for the transaction */
  channel?: string | null;
  /**
   * The recipient account, this should be a valid account number
   * @minLength 1
   */
  accountNumber: string;
  /** The recipient name */
  accountName?: string | null;
  /** The recipient bank identification code */
  bankIdentificationCode?: string | null;
  /** A message to be included for payee's reference, in this case, the recipient. */
  payeeNote?: string | null;
}

export interface SelfLiquidationCreateModel {
  transferType?: BankTransferType;
  /** @format uuid */
  walletId?: string;
  /** @format double */
  amount?: number;
  /** @minLength 1 */
  referenceNumber: string;
  /** @format uuid */
  transactionId?: string | null;
}

export interface StanbicAccountBalanceResponse {
  accountId?: string | null;
  accountStatus?: string | null;
  accountName?: string | null;
  accountCurrency?: string | null;
  accountType?: string | null;
  availableBalance?: string | null;
  mobile?: string | null;
  transactionType?: string | null;
  statusCode?: string | null;
  statusDescription?: string | null;
  requestId?: string | null;
}

export interface StanbicMiniStatement {
  requestId?: string | null;
  accountId?: string | null;
  transactionType?: string | null;
  numberOfTransactions?: string | null;
  accountStatus?: string | null;
  accountName?: string | null;
  accountType?: string | null;
  accountCurrency?: string | null;
  mobile?: string | null;
  statusCode?: string | null;
  transactionHistory?: StanbicMiniStatementTransactionHistory[] | null;
  statusDescription?: string | null;
}

export interface StanbicMiniStatementTransactionHistory {
  debitCreditType?: string | null;
  transactionDate?: string | null;
  transactionRemark?: string | null;
  transactionCurrency?: string | null;
  transactionAmount?: string | null;
  transactionBalance?: string | null;
  transactionId?: string | null;
}

export interface ToggleWalletPropertyRequest {
  /** @format uuid */
  walletId?: string;
  newState?: boolean;
}

export enum TopUpCategory {
  Manual = "Manual",
  BankNotification = "BankNotification",
}

export interface TopUpCreateModel {
  category?: TopUpCategory;
  /** @format uuid */
  walletId?: string;
  /** @format double */
  amount?: number;
  /** @minLength 1 */
  referenceNumber: string;
  memo?: string | null;
}

export enum TopUpStatus {
  Pending = "Pending",
  Processing = "Processing",
  Success = "Success",
  Failed = "Failed",
  AwaitingApproval = "AwaitingApproval",
  Rejected = "Rejected",
}

export interface TopUpViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  category?: TopUpCategory;
  createdBy?: string | null;
  createdByData?: ComboModel;
  memo?: string | null;
  status?: TopUpStatus;
  /** @format double */
  amount?: number;
  referenceNumber?: string | null;
  approvedBy?: string | null;
  approvedByData?: ComboModel;
  /** @format date-time */
  approvedAt?: string | null;
  approvedRemarks?: string | null;
  /** @format uuid */
  walletId?: string;
  wallet?: ComboModel;
  /** @format uuid */
  partnerId?: string;
  partner?: ComboModel;
}

export interface TopUpWalletRequest {
  /** @format uuid */
  walletId?: string;
  /** @format double */
  amount?: number;
  referenceNumber?: string | null;
  /** @format uuid */
  transactionId?: string | null;
}

export interface TotalInMonth {
  /** @format int32 */
  month?: number;
  /** @format int32 */
  count?: number;
  /** @format double */
  amount?: number;
}

export enum TransactionChargesCategory {
  ChargeWallet = "ChargeWallet",
  ChargeCustomer = "ChargeCustomer",
}

export interface UnauthorizedResult {
  /** @format int32 */
  statusCode?: number;
}

export interface UpdateWalletSlugModel {
  /** @format uuid */
  walletId?: string;
  slug?: string | null;
}

export interface UserViewModel {
  subject?: string | null;
  /** @format uuid */
  contactId?: string | null;
  roles?: string[] | null;
  name?: string | null;
  email?: string | null;
  clientId?: string | null;
  isClientCredentials?: boolean;
  /** @format uuid */
  partnerId?: string | null;
  authority?: string | null;
  identityProvider?: string | null;
  /** @format date-time */
  loginTime?: string | null;
  emailVerified?: boolean;
}

export enum Vendor {
  Mock = "Mock",
  Mtn = "Mtn",
  Airtel = "Airtel",
  Internal = "Internal",
  Stanbic = "Stanbic",
}

export interface VendorPortion {
  vendor?: Vendor;
  /** @format int32 */
  count?: number;
  /** @format double */
  amount?: number;
}

export interface VendorTest {
  transactionId?: string | null;
  referenceNo?: string | null;
  message?: string | null;
  msisdn?: string | null;
  /** @format int64 */
  amount?: number;
}

export interface WalletActivateModel {
  /** @format uuid */
  walletId?: string;
  canCollect?: boolean;
  canDisburse?: boolean;
  active?: boolean;
  reasonForDeactivation?: string | null;
}

export interface WalletBalance {
  currency?: Currency;
  /**
   * TransactionId
   * @format uuid
   */
  id?: string;
  /** TransactionId */
  name?: string | null;
  /**
   * Total balance on the account
   * @format double
   */
  actualBalance?: number;
  /**
   * Balance that can be used for transaction
   * @format double
   */
  availableBalance?: number;
  /** Url to be notified for collections */
  collectionCallBackUrl?: string | null;
  /** Url to be notified for disbursements */
  disbursementCallBackUrl?: string | null;
  /** Callback Security Header Key */
  callBackSecurityHeaderKey?: string | null;
  /** Callback Security Header Value */
  callBackSecurityHeaderValue?: string | null;
}

export interface WalletBeneficiaryCreateModel {
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  walletCode: string;
}

export interface WalletBeneficiaryUpdateModel {
  /** @format uuid */
  beneficiaryId?: string;
  /** @minLength 1 */
  name: string;
  walletCode?: string | null;
}

export enum WalletCallbackCategory {
  Collection = "Collection",
  Disbursement = "Disbursement",
  Deposit = "Deposit",
  Liquidation = "Liquidation",
}

export interface WalletCallbackCreateModel {
  /** @format uuid */
  walletId?: string;
  category?: WalletCallbackCategory;
  httpVerb?: HttpVerb;
  /** @minLength 1 */
  endpointUrl: string;
  credentials?: WalletCallbackCredential[] | null;
}

export interface WalletCallbackCredential {
  header?: string | null;
  value?: string | null;
}

export interface WalletCallbackCredentialViewModel {
  header?: string | null;
  value?: string | null;
}

export interface WalletCallbackUpdateModel {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  walletId?: string;
  category?: WalletCallbackCategory;
  httpVerb?: HttpVerb;
  /** @minLength 1 */
  endpointUrl: string;
  credentials: WalletCallbackCredential[];
}

export interface WalletCallbackViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format uuid */
  walletId?: string;
  wallet?: ComboModel;
  category?: WalletCallbackCategory;
  httpVerb?: HttpVerb;
  endpointUrl?: string | null;
  credentials?: WalletCallbackCredentialViewModel[] | null;
  /** @format uuid */
  partnerId?: string;
  createdBy?: string | null;
  createdByData?: ComboModel;
}

export interface WalletChargesViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  currency?: Currency;
  usingDefaultFees?: Record<string, boolean | null>;
  collectionFee?: string | null;
  disbursementFee?: string | null;
  vendorCollectionFee?: string | null;
  vendorDisbursementFee?: string | null;
  collectionFees?: PriceTier[] | null;
  disbursementFees?: PriceTier[] | null;
  vendorCollectionFees?: PriceTier[] | null;
  vendorDisbursementFees?: PriceTier[] | null;
}

export interface WalletCreateModel {
  /** @format uuid */
  id?: string | null;
  currency: Currency;
  chargeModel?: string | null;
  /** @minLength 1 */
  name: string;
  /** @maxLength 12 */
  shortName?: string | null;
  /** @format uuid */
  partnerId?: string;
}

export interface WalletMemberCreateModel {
  /** @format uuid */
  partnerMemberId?: string;
  partnerMember?: ComboModel;
  /** @format uuid */
  partnerId?: string;
  partner?: ComboModel;
  /** @format uuid */
  walletId?: string;
  wallet?: ComboModel;
}

export interface WalletMemberViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format uuid */
  partnerMemberId?: string;
  partnerMember?: ContactMiniViewModel;
  /** @format uuid */
  partnerId?: string;
  partner?: ComboModel;
  /** @format uuid */
  walletId?: string;
  wallet?: ComboModel;
  createdBy?: string | null;
  createdByData?: ComboModel;
}

export enum WalletNotificationCategory {
  Collection = "Collection",
  Disbursement = "Disbursement",
  Deposit = "Deposit",
  Liquidation = "Liquidation",
}

export interface WalletNotificationCreateModel {
  /** @format uuid */
  walletId?: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  name: string;
  category?: WalletNotificationCategory;
  recipientCategory?: WalletNotificationRecipientCategory;
  messageCategory?: WalletNotificationMessageCategory;
  /** @format uuid */
  templateId?: string;
}

export enum WalletNotificationMessageCategory {
  Sms = "Sms",
  Email = "Email",
}

export enum WalletNotificationRecipientCategory {
  Customer = "Customer",
  WalletOwner = "WalletOwner",
}

export interface WalletNotificationUpdateModel {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  walletId?: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  name: string;
  category?: WalletNotificationCategory;
  /** @format uuid */
  templateId?: string;
  recipientCategory?: WalletNotificationRecipientCategory;
  messageCategory?: WalletNotificationMessageCategory;
}

export interface WalletNotificationViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  name?: string | null;
  /** @format uuid */
  walletId?: string;
  wallet?: ComboModel;
  category?: WalletNotificationCategory;
  /** @format uuid */
  templateId?: string;
  template?: ComboModel;
  recipientCategory?: WalletNotificationRecipientCategory;
  messageCategory?: WalletNotificationMessageCategory;
  /** @format uuid */
  partnerId?: string;
  createdBy?: string | null;
  createdByData?: ComboModel;
}

export enum WalletOperation {
  TopUp = "TopUp",
  Deduct = "Deduct",
}

export interface WalletPinCreateModel {
  /** @format uuid */
  walletId?: string;
  /**
   * @minLength 4
   * @maxLength 5
   */
  pin: string;
}

export interface WalletPinEditModel {
  /** @format uuid */
  walletId?: string;
  /**
   * @minLength 4
   * @maxLength 5
   */
  oldPin: string;
  /**
   * @minLength 4
   * @maxLength 5
   */
  newPin: string;
}

export interface WalletPinResetModel {
  /** @format uuid */
  walletId?: string;
  /**
   * @minLength 4
   * @maxLength 5
   */
  newPin: string;
}

export interface WalletSlugCollectModel {
  /** @minLength 1 */
  slug: string;
  /** @format double */
  amount: number;
  externalId?: string | null;
  /** @minLength 1 */
  payer: string;
  /** @minLength 1 */
  payerNote: string;
}

export interface WalletSlugCollectResponse {
  /**
   * Request ID, to be used for status
   * @format uuid
   */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  category?: PaymentCategory;
  currency?: Currency;
  status?: RequestStatus;
  statusCode?: string | null;
  statusMessage?: string | null;
  payer?: string | null;
  payerNote?: string | null;
  /** @format double */
  amount?: number;
  vendor?: Vendor;
  payeeNote?: string | null;
  vendorTransactionId?: string | null;
}

export interface WalletSlugDetails {
  slug?: string | null;
  name?: string | null;
  validDomains?: string | null;
}

export enum WalletTransactionCategory {
  Disbursement = "Disbursement",
  Collection = "Collection",
  Deposit = "Deposit",
  Liquidation = "Liquidation",
  RevenueShare = "RevenueShare",
  TransactionCharge = "TransactionCharge",
  Transfer = "Transfer",
}

export interface WalletTransactionPayment {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  lastUpdated?: string | null;
  isDeleted?: boolean;
  status?: RequestStatus;
  vendor?: Vendor;
  /** @format double */
  amount?: number;
  msisdn?: string | null;
  vendorTransactionId?: string | null;
  /** @format double */
  transactionCharge?: number;
  /** @format double */
  vendorCharge?: number;
}

export interface WalletTransactionViewModel {
  narration?: string;
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  operation?: WalletOperation;
  wallet?: ComboModel;
  /** @format uuid */
  walletId?: string;
  /** @format uuid */
  requestId?: string;
  /** @format int64 */
  transactionNumber?: number;
  /** @format double */
  amount?: number;
  memo?: string | null;
  /** @format double */
  balance?: number;
  category?: WalletTransactionCategory;
  disbursement?: WalletTransactionPayment;
  collection?: WalletTransactionPayment;
}

export interface WalletTransactionViewModelPaginatedListViewModel {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  total?: number;
  data?: WalletTransactionViewModel[] | null;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
}

export interface WalletUpdateModel {
  /** @format uuid */
  walletId?: string;
  /** @maxLength 300 */
  name?: string | null;
  /** @maxLength 12 */
  shortName?: string | null;
}

export interface WalletViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  currency?: Currency;
  chargeModel?: string | null;
  name?: string | null;
  slug?: string | null;
  shortName?: string | null;
  code?: string | null;
  slugDomains?: string | null;
  canCollect?: boolean | null;
  canDisburse?: boolean | null;
  hasMakerChecker?: boolean;
  active?: boolean;
  enableFineGrainAccess?: boolean;
  /** @format uuid */
  partnerId?: string;
  partner?: ComboModel;
  /** @format double */
  balance?: number;
  collectionCallBackUrl?: string | null;
  disbursementCallBackUrl?: string | null;
  callBackSecurityHeaderKey?: string | null;
}
