import { createSvgIcon } from '@mui/material/utils';

const MoneyOut = createSvgIcon(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="100%"
            viewBox="0 0 1024 1024"
        >
            <path fill="currentColor"
                  d="
M518.536804,1.000000
	C532.167725,4.489533 540.365356,13.861938 548.149170,24.356150
	C580.088440,67.417244 612.418091,110.188606 644.565247,153.095703
	C657.647583,170.556824 655.270691,191.394943 638.786621,203.918716
	C632.038025,209.045975 624.231201,211.005356 615.852539,211.002869
	C595.019531,210.996674 574.186523,211.000839 553.353516,211.000854
	C551.722839,211.000854 550.092163,211.000870 548.048828,211.000870
	C548.048828,269.903748 548.048828,328.480164 548.048828,387.276184
	C524.537842,387.276184 501.443909,387.276184 478.111389,387.276184
	C478.111389,328.585938 478.111389,270.126892 478.111389,211.000870
	C476.296051,211.000870 474.710693,211.000916 473.125336,211.000870
	C451.625671,211.000320 430.124695,211.149826 408.626770,210.956299
	C387.834320,210.769135 372.167084,194.400803 373.059906,174.096771
	C373.400024,166.361984 376.507812,159.632919 381.090973,153.523514
	C415.362518,107.838913 449.667358,62.179253 483.886566,16.455507
	C489.506805,8.945747 496.706360,4.033107 505.864380,1.342487
	C510.024536,1.000000 514.049072,1.000000 518.536804,1.000000
z"/>
            <path fill="currentColor"
                  d="
M760.963196,957.000000
	C760.963196,973.799683 760.963196,990.099426 760.963196,1006.684448
	C512.609558,1006.684448 264.594482,1006.684448 16.338371,1006.684448
	C16.338371,886.576843 16.338371,766.660583 16.338371,646.266724
	C57.603912,646.266724 98.989861,646.266724 140.764618,646.266724
	C140.764618,601.724792 140.764618,557.656799 140.764618,513.162720
	C182.219162,513.162720 223.287354,513.162720 264.763306,513.162720
	C264.763306,480.003876 264.763306,447.267639 264.763306,414.258240
	C513.025208,414.258240 760.877563,414.258240 1008.920532,414.258240
	C1008.920532,534.340149 1008.920532,654.271057 1008.920532,774.554688
	C967.854492,774.554688 926.788208,774.554688 885.241821,774.554688
	C885.241821,807.800903 885.241821,840.699951 885.241821,873.919983
	C843.665955,873.919983 802.570312,873.919983 760.963196,873.919983
	C760.963196,901.660767 760.963196,929.080383 760.963196,957.000000
M886.917969,704.842529
	C895.185120,704.842529 903.452271,704.842529 912.035278,704.842529
	C912.035278,690.263062 912.035278,676.326843 912.035278,661.892517
	C921.252686,661.892517 930.152161,661.892517 938.802979,661.892517
	C938.802979,616.331055 938.802979,571.274658 938.802979,525.843567
	C929.679321,525.843567 920.926819,525.843567 911.837402,525.843567
	C911.837402,511.705719 911.837402,497.942139 911.837402,484.096466
	C728.432983,484.096466 545.380493,484.096466 362.218384,484.096466
	C362.218384,493.776917 362.218384,503.195862 362.218384,513.131226
	C536.635132,513.131226 710.844849,513.131226 885.160461,513.131226
	C885.160461,515.811584 885.159668,517.974182 885.160461,520.136841
	C885.183472,581.633057 885.206665,643.129211 885.160645,705.608276
	C885.160645,727.620667 885.160645,749.633057 885.160645,771.645447
	C885.434814,771.644226 885.708984,771.643005 885.983154,771.641785
	C885.983154,749.408813 885.983154,727.175781 886.917969,704.842529
M677.494690,758.096313
	C672.887939,758.096313 668.281189,758.096313 663.293945,758.096313
	C663.293945,743.772339 663.293945,730.020935 663.293945,716.164490
	C479.878601,716.164490 296.862793,716.164490 113.478912,716.164490
	C113.478912,730.260071 113.478912,744.029785 113.478912,758.261780
	C104.202026,758.261780 95.284973,758.261780 86.285667,758.261780
	C86.285667,803.831238 86.285667,848.929626 86.285667,894.569275
	C95.376305,894.569275 104.295357,894.569275 113.737434,894.569275
	C113.737434,908.980469 113.737434,922.861145 113.737434,936.776611
	C297.177948,936.776611 480.079010,936.776611 663.510132,936.776611
	C663.510132,922.680298 663.510132,908.799500 663.510132,894.361694
	C672.929199,894.361694 681.828003,894.361694 690.629639,894.361694
	C690.629639,848.691956 690.629639,803.596558 690.629639,758.096313
	C686.414551,758.096313 682.451172,758.096313 677.494690,758.096313
M760.962646,700.500000
	C760.962646,734.915222 760.962646,769.330505 760.962646,803.737549
	C770.207275,803.737549 778.950623,803.737549 788.137695,803.737549
	C788.137695,789.571167 788.137695,775.661011 788.137695,761.405518
	C797.362915,761.405518 806.142273,761.405518 814.989563,761.405518
	C814.989563,715.955688 814.989563,670.864136 814.989563,625.394043
	C805.990723,625.394043 797.226257,625.394043 787.986877,625.394043
	C787.986877,611.126587 787.986877,597.227234 787.986877,583.209473
	C604.475037,583.209473 421.445435,583.209473 237.940643,583.209473
	C237.940643,597.312927 237.940643,611.216003 237.940643,625.468384
	C228.695801,625.468384 219.921448,625.468384 210.953461,625.468384
	C210.953461,632.413879 210.953461,638.998169 210.953461,645.996033
	C212.083893,645.996033 213.052338,645.996033 214.020782,645.996033
	C290.832275,645.996094 367.643860,646.020203 444.455078,645.869324
	C446.554596,645.865173 449.133453,644.453857 450.677002,642.894958
	C485.216217,608.012024 540.909790,608.049805 575.265625,642.932678
	C576.962341,644.655396 580.049316,645.863892 582.492981,645.870605
	C640.142761,646.030212 697.793213,645.991394 755.443481,645.988953
	C757.208069,645.988892 758.972656,645.988953 760.962646,645.988953
	C760.962646,664.223755 760.962646,681.861877 760.962646,700.500000
z"/>
            <path fill="#FFFFFF"
                  d="
M677.991211,758.096313
	C682.451172,758.096313 686.414551,758.096313 690.629639,758.096313
	C690.629639,803.596558 690.629639,848.691956 690.629639,894.361694
	C681.828003,894.361694 672.929199,894.361694 663.510132,894.361694
	C663.510132,908.799500 663.510132,922.680298 663.510132,936.776611
	C480.079010,936.776611 297.177948,936.776611 113.737434,936.776611
	C113.737434,922.861145 113.737434,908.980469 113.737434,894.569275
	C104.295357,894.569275 95.376305,894.569275 86.285667,894.569275
	C86.285667,848.929626 86.285667,803.831238 86.285667,758.261780
	C95.284973,758.261780 104.202026,758.261780 113.478912,758.261780
	C113.478912,744.029785 113.478912,730.260071 113.478912,716.164490
	C296.862793,716.164490 479.878601,716.164490 663.293945,716.164490
	C663.293945,730.020935 663.293945,743.772339 663.293945,758.096313
	C668.281189,758.096313 672.887939,758.096313 677.991211,758.096313
M367.033112,752.380493
	C324.343445,763.801025 297.067230,802.555786 302.390106,846.957092
	C308.302765,896.278442 352.300018,929.443420 401.692139,921.997559
	C434.835907,917.001221 458.125519,897.991882 469.672668,866.621765
	C481.343506,834.915466 476.002808,804.928345 453.702698,779.453857
	C430.938538,753.449402 401.512421,745.357727 367.033112,752.380493
z"/>
            <path fill="#FFFFFF"
                  d="
M760.962646,700.000000
	C760.962646,681.861877 760.962646,664.223755 760.962646,645.988953
	C758.972656,645.988953 757.208069,645.988892 755.443481,645.988953
	C697.793213,645.991394 640.142761,646.030212 582.492981,645.870605
	C580.049316,645.863892 576.962341,644.655396 575.265625,642.932678
	C540.909790,608.049805 485.216217,608.012024 450.677002,642.894958
	C449.133453,644.453857 446.554596,645.865173 444.455078,645.869324
	C367.643860,646.020203 290.832275,645.996094 214.020782,645.996033
	C213.052338,645.996033 212.083893,645.996033 210.953461,645.996033
	C210.953461,638.998169 210.953461,632.413879 210.953461,625.468384
	C219.921448,625.468384 228.695801,625.468384 237.940643,625.468384
	C237.940643,611.216003 237.940643,597.312927 237.940643,583.209473
	C421.445435,583.209473 604.475037,583.209473 787.986877,583.209473
	C787.986877,597.227234 787.986877,611.126587 787.986877,625.394043
	C797.226257,625.394043 805.990723,625.394043 814.989563,625.394043
	C814.989563,670.864136 814.989563,715.955688 814.989563,761.405518
	C806.142273,761.405518 797.362915,761.405518 788.137695,761.405518
	C788.137695,775.661011 788.137695,789.571167 788.137695,803.737549
	C778.950623,803.737549 770.207275,803.737549 760.962646,803.737549
	C760.962646,769.330505 760.962646,734.915222 760.962646,700.000000
z"/>
            <path fill="#FFFFFF"
                  d="
M885.229858,704.625427
	C885.206665,643.129211 885.183472,581.633057 885.160461,520.136841
	C885.159668,517.974182 885.160461,515.811584 885.160461,513.131226
	C710.844849,513.131226 536.635132,513.131226 362.218384,513.131226
	C362.218384,503.195862 362.218384,493.776917 362.218384,484.096466
	C545.380493,484.096466 728.432983,484.096466 911.837402,484.096466
	C911.837402,497.942139 911.837402,511.705719 911.837402,525.843567
	C920.926819,525.843567 929.679321,525.843567 938.802979,525.843567
	C938.802979,571.274658 938.802979,616.331055 938.802979,661.892517
	C930.152161,661.892517 921.252686,661.892517 912.035278,661.892517
	C912.035278,676.326843 912.035278,690.263062 912.035278,704.842529
	C903.452271,704.842529 895.185120,704.842529 886.294189,704.737549
	C885.670349,704.632629 885.229858,704.625427 885.229858,704.625427
z"/>

            <path fill="currentColor"
                  d="
M367.432434,752.261841
	C401.512421,745.357727 430.938538,753.449402 453.702698,779.453857
	C476.002808,804.928345 481.343506,834.915466 469.672668,866.621765
	C458.125519,897.991882 434.835907,917.001221 401.692139,921.997559
	C352.300018,929.443420 308.302765,896.278442 302.390106,846.957092
	C297.067230,802.555786 324.343445,763.801025 367.432434,752.261841
z"/>
        </svg>,
        'MoneyOut'
)
;

export default MoneyOut;
