import { createSvgIcon } from '@mui/material/utils';

const MessagingLogo = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 500 500">
            <path fill="#1E5AE3"
                  d="
M501.000000,496.000000
	C501.000000,496.444336 501.000000,496.888672 500.531342,497.667908
	C474.367493,498.049103 448.672302,498.095367 422.513855,498.077759
	C395.226746,498.009979 368.402954,498.002167 341.579132,498.010162
	C339.581207,498.010773 337.572876,498.014191 335.587006,498.202484
	C306.271606,500.981750 278.052734,495.980896 251.234833,484.487671
	C176.655121,452.525391 133.348526,373.436157 146.191818,293.499146
	C158.260162,218.385544 217.426224,158.618851 292.059387,146.576767
	C326.170349,141.072952 359.345184,144.047073 390.730591,158.303650
	C457.510712,188.638031 493.838318,241.057129 498.637207,314.151215
	C501.212616,353.378754 490.092224,389.651031 467.560242,421.956024
	C459.904236,432.932678 450.616913,442.771515 441.789246,453.476776
	C448.523346,458.329346 455.787170,463.563599 463.304565,469.029510
	C472.229126,475.639038 480.863586,482.067474 489.588318,488.371033
	C493.294769,491.048889 497.190369,493.464966 501.000000,496.000000
M417.994965,469.500916
	C411.969543,465.154327 405.944122,460.807739 399.473816,456.140198
	C401.496399,454.829102 402.984314,453.857819 404.478790,452.896729
	C455.067017,420.365265 481.934082,364.063110 472.565308,302.578613
	C461.652588,230.961746 402.679199,183.803940 342.932037,175.882202
	C288.104095,168.612701 242.069626,186.081879 207.131699,228.819565
	C173.043472,270.517883 165.863449,318.725555 181.222519,369.537903
	C200.909744,434.669098 258.348633,475.934082 326.323700,475.993286
	C357.810608,476.020721 389.297546,475.997833 420.784485,475.997772
	C422.353729,475.997772 423.922974,475.997803 426.885651,475.997803
	C423.311340,473.391357 420.939911,471.662109 417.994965,469.500916
z"/>
            <path fill="#1E5AE3"
                  d="
M345.194336,123.635773
	C343.697479,124.356270 342.035583,125.066124 340.393555,125.022781
	C318.393463,124.442039 296.545258,124.894066 275.029694,130.604172
	C220.520370,145.070602 178.967087,176.569656 150.552689,225.155640
	C130.983887,258.616455 122.727516,295.027954 124.269829,333.683472
	C124.501335,339.485840 125.333755,345.264282 125.917625,351.462708
	C84.955643,351.871674 43.964241,351.871674 1.572559,351.871674
	C21.628090,337.153198 40.788536,323.091614 60.038616,308.964264
	C53.852905,302.007874 47.490551,295.501526 41.856586,288.416016
	C-11.322817,221.535385 -10.414482,129.227402 45.397923,64.586311
	C86.577461,16.892799 139.801315,-3.852021 202.208572,5.071107
	C272.233398,15.083411 319.344025,55.359562 344.545807,121.350281
	C344.780548,121.964920 344.877350,122.632248 345.194336,123.635773
z"/>
            <path fill="#FFFFFF"
                  d="
M418.281738,469.716858
	C420.939911,471.662109 423.311340,473.391357 426.885651,475.997803
	C423.922974,475.997803 422.353729,475.997772 420.784485,475.997772
	C389.297546,475.997833 357.810608,476.020721 326.323700,475.993286
	C258.348633,475.934082 200.909744,434.669098 181.222519,369.537903
	C165.863449,318.725555 173.043472,270.517883 207.131699,228.819565
	C242.069626,186.081879 288.104095,168.612701 342.932037,175.882202
	C402.679199,183.803940 461.652588,230.961746 472.565308,302.578613
	C481.934082,364.063110 455.067017,420.365265 404.478790,452.896729
	C402.984314,453.857819 401.496399,454.829102 399.473816,456.140198
	C405.944122,460.807739 411.969543,465.154327 418.281738,469.716858
M318.500000,251.001129
	C297.428558,251.001129 276.357086,251.001129 255.347198,251.001129
	C255.347198,261.349182 255.347198,271.066956 255.347198,280.589203
	C305.183807,280.589203 354.671631,280.589203 404.080353,280.589203
	C404.080353,270.591766 404.080353,261.006409 404.080353,251.001129
	C375.695038,251.001129 347.597534,251.001129 318.500000,251.001129
M404.131042,311.672180
	C402.527771,311.448517 400.924652,311.030548 399.321198,311.029358
	C352.876343,310.994385 306.431458,310.998993 259.986572,311.010010
	C258.392395,311.010376 256.798218,311.142395 255.378235,311.205292
	C255.378235,321.411743 255.378235,331.131195 255.378235,340.737915
	C305.147400,340.737915 354.537231,340.737915 404.326141,340.737915
	C404.326141,331.200165 404.326141,321.917603 404.131042,311.672180
M329.370392,391.476562
	C329.370392,384.745331 329.370392,378.014130 329.370392,371.223175
	C304.234375,371.223175 279.853973,371.223175 255.246765,371.223175
	C255.246765,381.155304 255.246765,390.865051 255.246765,400.695435
	C280.012970,400.695435 304.530640,400.695435 329.370667,400.695435
	C329.370667,397.752869 329.370667,395.111511 329.370392,391.476562
z"/>
            <path fill="#1E5AE3"
                  d="
M319.000000,251.001129
	C347.597534,251.001129 375.695038,251.001129 404.080353,251.001129
	C404.080353,261.006409 404.080353,270.591766 404.080353,280.589203
	C354.671631,280.589203 305.183807,280.589203 255.347198,280.589203
	C255.347198,271.066956 255.347198,261.349182 255.347198,251.001129
	C276.357086,251.001129 297.428558,251.001129 319.000000,251.001129
z"/>
            <path fill="#1E5AE3"
                  d="
M404.228577,312.153625
	C404.326141,321.917603 404.326141,331.200165 404.326141,340.737915
	C354.537231,340.737915 305.147400,340.737915 255.378235,340.737915
	C255.378235,331.131195 255.378235,321.411743 255.378235,311.205292
	C256.798218,311.142395 258.392395,311.010376 259.986572,311.010010
	C306.431458,310.998993 352.876343,310.994385 399.321198,311.029358
	C400.924652,311.030548 402.527771,311.448517 404.228577,312.153625
z"/>
            <path fill="#1E5AE3"
                  d="
M329.370544,391.973389
	C329.370667,395.111511 329.370667,397.752869 329.370667,400.695435
	C304.530640,400.695435 280.012970,400.695435 255.246765,400.695435
	C255.246765,390.865051 255.246765,381.155304 255.246765,371.223175
	C279.853973,371.223175 304.234375,371.223175 329.370392,371.223175
	C329.370392,378.014130 329.370392,384.745331 329.370544,391.973389
z"/>
    </svg>,
    'MessagingLogo'
);

export default MessagingLogo;
