import 'react-perfect-scrollbar/dist/css/styles.css';
import 'nprogress/nprogress.css';
import 'react-toastify/dist/ReactToastify.css';
import './__mocks__';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { SettingsProvider } from './contexts/SettingsContext';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import HttpService from './contexts/HttpService';
import KeycloakAuthService from './contexts/KeycloakAuthService';
import './index.css';
import App from './App';

import reportWebVitals from './reportWebVitals';
import { fetchAndActivate } from 'firebase/remote-config';
import { gtmConfig, remoteConfig } from './config';
import gtm from './lib/gtm';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const renderApp = () => root.render(
    <React.StrictMode>
        <HelmetProvider>
            <StyledEngineProvider injectFirst>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <SettingsProvider>
                        <Router>
                            <App/>
                        </Router>
                    </SettingsProvider>
                </LocalizationProvider>
            </StyledEngineProvider>
        </HelmetProvider>
    </React.StrictMode>
);

const startApp = () => {
  KeycloakAuthService.initKeycloak(renderApp);
  HttpService.configure();
};

startApp();
fetchAndActivate(remoteConfig)
  .then(() => {
    console.log('Remote config fetched and activated');
  })
  .catch((err) => {
    console.error(err);
  });
gtm.initialize(gtmConfig);
serviceWorkerRegistration.register();
reportWebVitals();
