import { createSvgIcon } from '@mui/material/utils';

const PayLogo = createSvgIcon(
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 500"
        width="100%"
        fill="currentColor"
    >
        <path fill="#FFFFFF"
              d="
M499.001221,223.000000
	C499.001221,248.330215 499.162964,273.162018 498.921600,297.989868
	C498.825165,307.912018 500.243286,317.933502 495.533081,327.639069
	C494.050629,330.693756 496.502838,335.655701 497.148346,339.745636
	C501.306519,366.092163 497.566681,391.510498 486.577209,415.573639
	C467.295166,457.794556 434.559052,484.038910 389.568268,495.352997
	C375.027039,499.009766 360.160248,500.665100 345.595245,498.679718
	C318.058838,494.926178 292.547424,485.261139 270.745514,467.234894
	C244.157028,445.251068 227.989395,417.159393 221.413086,383.444000
	C220.369461,378.093506 219.710587,372.636566 219.341049,367.197052
	C219.094650,363.570129 217.416672,362.912506 214.226959,362.945984
	C201.396652,363.080475 188.564102,363.001282 175.732391,363.001251
	C135.904068,363.001160 96.075546,363.072113 56.247585,362.953186
	C45.797356,362.921997 35.732132,360.687561 26.748497,355.078308
	C10.848591,345.150696 3.214982,330.093567 3.146346,311.938538
	C2.821262,225.950607 2.839109,139.960266 3.099739,53.971886
	C3.178454,28.001486 21.559658,6.217113 47.878571,3.946819
	C52.503521,3.547867 57.142323,3.040103 61.774902,3.038319
	C189.592117,2.989076 317.409363,2.975843 445.226562,3.036021
	C457.450439,3.041777 469.027985,5.838922 478.935272,13.421135
	C489.565186,21.556442 496.244934,32.319241 497.846802,45.638680
	C498.855225,54.023769 498.939362,62.553368 498.955048,71.019035
	C499.048462,121.512550 499.001221,172.006317 499.001221,223.000000
M325.608002,223.056641
	C390.452209,209.700928 440.833405,232.095078 478.679718,285.107361
	C478.679718,247.129471 478.679718,209.207108 478.679718,171.365356
	C326.638519,171.365356 174.917587,171.365356 23.000387,171.365356
	C23.000387,173.218765 23.000385,174.707611 23.000385,176.196472
	C23.000399,220.861969 22.986557,265.527466 23.006586,310.192963
	C23.015837,330.823395 35.277691,342.992859 56.019291,342.996582
	C108.851242,343.006073 161.683197,342.999725 214.515152,342.999725
	C216.293579,342.999725 218.072006,342.999695 219.085144,342.999695
	C221.605392,332.913574 223.151550,323.279999 226.409363,314.264954
	C243.470566,267.052979 276.344330,236.650757 325.608002,223.056641
M280.746735,449.765137
	C316.796295,480.961517 369.481934,488.145050 413.832245,465.614227
	C460.901093,441.702393 487.869965,386.263580 476.572662,334.766663
	C465.774658,285.545746 427.764587,249.160934 378.176270,240.577423
	C327.807007,231.858749 274.557770,258.925964 252.056473,304.685608
	C227.312256,355.006500 238.330048,411.572601 280.746735,449.765137
M55.500000,70.999290
	C196.582977,70.999290 337.665955,70.999290 478.999359,70.999290
	C478.999359,65.844200 479.001404,61.362541 478.998932,56.880882
	C478.986877,34.891769 467.056213,23.001650 444.993011,23.001415
	C315.665771,23.000036 186.338547,23.000443 57.011314,23.003693
	C55.178627,23.003738 53.338837,22.952398 51.514412,23.087633
	C40.577061,23.898367 32.330158,29.002752 26.830223,38.550816
	C20.934155,48.786583 23.586107,59.851448 23.039837,70.999290
	C33.892967,70.999290 44.196484,70.999290 55.500000,70.999290
M238.500000,101.001060
	C166.748474,101.001060 94.996933,101.001060 23.389938,101.001060
	C23.389938,114.743217 23.389938,127.801849 23.389938,140.747223
	C175.377975,140.747223 326.977020,140.747223 478.698486,140.747223
	C478.698486,127.437225 478.698486,114.380638 478.698486,101.001060
	C398.765564,101.001060 319.132782,101.001060 238.500000,101.001060
z"/>
        <path fill="#1E5AE3"
              d="
M325.204712,223.144257
	C276.344330,236.650757 243.470566,267.052979 226.409363,314.264954
	C223.151550,323.279999 221.605392,332.913574 219.085144,342.999695
	C218.072006,342.999695 216.293579,342.999725 214.515152,342.999725
	C161.683197,342.999725 108.851242,343.006073 56.019291,342.996582
	C35.277691,342.992859 23.015837,330.823395 23.006586,310.192963
	C22.986557,265.527466 23.000399,220.861969 23.000385,176.196472
	C23.000385,174.707611 23.000387,173.218765 23.000387,171.365356
	C174.917587,171.365356 326.638519,171.365356 478.679718,171.365356
	C478.679718,209.207108 478.679718,247.129471 478.679718,285.107361
	C440.833405,232.095078 390.452209,209.700928 325.204712,223.144257
M62.112003,202.462845
	C57.591835,207.572708 58.499626,214.295258 64.284042,215.400421
	C66.819328,215.884842 70.949051,214.627182 72.327690,212.705963
	C73.707581,210.783020 73.498177,206.568237 72.302132,204.212769
	C70.416069,200.498459 66.547729,200.206284 62.112003,202.462845
M105.184570,205.233826
	C105.097626,206.225250 104.935959,207.216690 104.936440,208.208084
	C104.938789,212.978806 107.685860,215.968445 112.005440,215.958969
	C116.382072,215.949341 119.600327,212.822311 118.880936,208.203430
	C118.512764,205.839600 116.707748,202.951096 114.690063,201.759857
	C111.265526,199.738022 107.976898,201.438629 105.184570,205.233826
M49.225086,203.400620
	C47.950523,202.663132 46.755035,201.547516 45.386116,201.261230
	C41.733536,200.497375 38.666416,201.799377 37.140892,205.263794
	C35.458988,209.083313 37.159897,213.534027 40.512135,215.200241
	C43.951561,216.909760 48.665180,215.925079 49.943714,212.375275
	C50.831783,209.909622 49.902145,206.789261 49.225086,203.400620
M95.838051,204.805710
	C94.764297,203.805771 93.849403,202.476608 92.587067,201.867508
	C88.778740,200.029938 85.455154,201.066025 83.014763,204.416901
	C80.768959,207.500580 81.761871,212.257446 84.915398,214.566925
	C87.974762,216.807419 92.754539,216.617844 94.624710,213.419220
	C95.891724,211.252197 95.628960,208.190750 95.838051,204.805710
M141.964310,210.084717
	C142.449387,206.460785 141.534500,203.058716 137.952698,201.980270
	C135.606812,201.273941 131.873413,201.421097 130.311722,202.863953
	C128.560654,204.481750 127.540710,208.230377 128.099442,210.605713
	C128.589752,212.690140 131.445267,215.031723 133.715561,215.700943
	C137.500961,216.816772 140.257629,214.417694 141.964310,210.084717
z"/>
        <path fill="#1E5AE3"
              d="
M280.484192,449.526306
	C238.330048,411.572601 227.312256,355.006500 252.056473,304.685608
	C274.557770,258.925964 327.807007,231.858749 378.176270,240.577423
	C427.764587,249.160934 465.774658,285.545746 476.572662,334.766663
	C487.869965,386.263580 460.901093,441.702393 413.832245,465.614227
	C369.481934,488.145050 316.796295,480.961517 280.484192,449.526306
M418.852417,339.354584
	C421.954529,335.651764 425.635895,332.277863 427.982147,328.145844
	C429.990204,324.609436 431.521545,320.163605 431.368744,316.192932
	C431.154388,310.623291 427.030640,306.698395 421.711029,305.295685
	C416.169312,303.834351 410.869965,304.749268 406.786163,309.627869
	C391.603485,327.765503 376.308167,345.808868 361.085938,363.913483
	C355.986755,369.978149 350.979492,376.120087 345.752777,382.441345
	C341.798981,378.229675 338.288330,374.451019 334.734833,370.713043
	C327.848145,363.468964 321.095703,356.087463 313.987122,349.067169
	C308.184265,343.336365 300.367554,343.449463 294.744690,348.806305
	C288.992828,354.286072 287.892365,362.094452 292.943787,367.588959
	C307.261047,383.162079 321.743256,398.591888 336.488892,413.758881
	C342.379333,419.817596 350.533142,419.238495 356.542114,413.243591
	C358.071716,411.717529 359.437927,410.023163 360.831329,408.365845
	C380.019440,385.543091 399.199158,362.713287 418.852417,339.354584
z"/>
        <path fill="#1E5AE3"
              d="
M55.000000,70.999290
	C44.196484,70.999290 33.892967,70.999290 23.039837,70.999290
	C23.586107,59.851448 20.934155,48.786583 26.830223,38.550816
	C32.330158,29.002752 40.577061,23.898367 51.514412,23.087633
	C53.338837,22.952398 55.178627,23.003738 57.011314,23.003693
	C186.338547,23.000443 315.665771,23.000036 444.993011,23.001415
	C467.056213,23.001650 478.986877,34.891769 478.998932,56.880882
	C479.001404,61.362541 478.999359,65.844200 478.999359,70.999290
	C337.665955,70.999290 196.582977,70.999290 55.000000,70.999290
z"/>
        <path fill="#1E5AE3"
              d="
M239.000000,101.001060
	C319.132782,101.001060 398.765564,101.001060 478.698486,101.001060
	C478.698486,114.380638 478.698486,127.437225 478.698486,140.747223
	C326.977020,140.747223 175.377975,140.747223 23.389938,140.747223
	C23.389938,127.801849 23.389938,114.743217 23.389938,101.001060
	C94.996933,101.001060 166.748474,101.001060 239.000000,101.001060
z"/>
        <path fill="#FFFFFF"
              d="
M62.436714,202.247528
	C66.547729,200.206284 70.416069,200.498459 72.302132,204.212769
	C73.498177,206.568237 73.707581,210.783020 72.327690,212.705963
	C70.949051,214.627182 66.819328,215.884842 64.284042,215.400421
	C58.499626,214.295258 57.591835,207.572708 62.436714,202.247528
z"/>
        <path fill="#FFFFFF"
              d="
M105.376740,204.875519
	C107.976898,201.438629 111.265526,199.738022 114.690063,201.759857
	C116.707748,202.951096 118.512764,205.839600 118.880936,208.203430
	C119.600327,212.822311 116.382072,215.949341 112.005440,215.958969
	C107.685860,215.968445 104.938789,212.978806 104.936440,208.208084
	C104.935959,207.216690 105.097626,206.225250 105.376740,204.875519
z"/>
        <path fill="#FFFFFF"
              d="
M49.504845,203.681015
	C49.902145,206.789261 50.831783,209.909622 49.943714,212.375275
	C48.665180,215.925079 43.951561,216.909760 40.512135,215.200241
	C37.159897,213.534027 35.458988,209.083313 37.140892,205.263794
	C38.666416,201.799377 41.733536,200.497375 45.386116,201.261230
	C46.755035,201.547516 47.950523,202.663132 49.504845,203.681015
z"/>
        <path fill="#FFFFFF"
              d="
M95.944221,205.167511
	C95.628960,208.190750 95.891724,211.252197 94.624710,213.419220
	C92.754539,216.617844 87.974762,216.807419 84.915398,214.566925
	C81.761871,212.257446 80.768959,207.500580 83.014763,204.416901
	C85.455154,201.066025 88.778740,200.029938 92.587067,201.867508
	C93.849403,202.476608 94.764297,203.805771 95.944221,205.167511
z"/>
        <path fill="#FFFFFF"
              d="
M141.898010,210.477875
	C140.257629,214.417694 137.500961,216.816772 133.715561,215.700943
	C131.445267,215.031723 128.589752,212.690140 128.099442,210.605713
	C127.540710,208.230377 128.560654,204.481750 130.311722,202.863953
	C131.873413,201.421097 135.606812,201.273941 137.952698,201.980270
	C141.534500,203.058716 142.449387,206.460785 141.898010,210.477875
z"/>
        <path fill="#FFFFFF"
              d="
M418.616699,339.619873
	C399.199158,362.713287 380.019440,385.543091 360.831329,408.365845
	C359.437927,410.023163 358.071716,411.717529 356.542114,413.243591
	C350.533142,419.238495 342.379333,419.817596 336.488892,413.758881
	C321.743256,398.591888 307.261047,383.162079 292.943787,367.588959
	C287.892365,362.094452 288.992828,354.286072 294.744690,348.806305
	C300.367554,343.449463 308.184265,343.336365 313.987122,349.067169
	C321.095703,356.087463 327.848145,363.468964 334.734833,370.713043
	C338.288330,374.451019 341.798981,378.229675 345.752777,382.441345
	C350.979492,376.120087 355.986755,369.978149 361.085938,363.913483
	C376.308167,345.808868 391.603485,327.765503 406.786163,309.627869
	C410.869965,304.749268 416.169312,303.834351 421.711029,305.295685
	C427.030640,306.698395 431.154388,310.623291 431.368744,316.192932
	C431.521545,320.163605 429.990204,324.609436 427.982147,328.145844
	C425.635895,332.277863 421.954529,335.651764 418.616699,339.619873
z"/>
    </svg>,
    'PayLogo'
);

export default PayLogo;
