import type { FC } from 'react';
import { Box, Container, Divider, Grid, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import Logo from './Logo';

const Footer: FC<React.PropsWithChildren<unknown>> = (props) => (
  <Box
    sx={{
      backgroundColor: 'background.default',
      pb: 6,
      pt: {
        md: 15,
        xs: 6
      }
    }}
    {...props}
  >
    <Container maxWidth="lg">
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          md={3}
          sm={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            order: {
              md: 1,
              xs: 4
            }
          }}
          xs={12}
        >
          <Logo />
          <Box sx={{ mt: 1 }}>
            <Typography
              color="textSecondary"
              variant="caption"
            >
              ioTec Limited
              <br />
              support@iotec.io
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider
        sx={{
          borderColor: (theme) => alpha(theme.palette.primary.contrastText, 0.12),
          my: 6
        }}
      />
      <Typography
        color="textSecondary"
        variant="caption"
      >
        All Rights Reserved.
      </Typography>
    </Container>
  </Box>
);

export default Footer;
