import { useContext } from 'react';
import { OAuth2ContextType } from '../contexts/OAuth2Context';
import KeycloakContext from '../contexts/KeycloakContext';


const useAuth = () => {
  return  useContext<OAuth2ContextType>(KeycloakContext);
};

export default useAuth;
