import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

interface IProps {
  onChange: (value: boolean | null) => any
  onBlur: (value: boolean | null) => any
  value: boolean | null
  label?: string
  name?: string
  variant?: 'inline'
  inputVariant?: 'standard' | 'outlined' | 'filled'
  size?: 'small' | 'medium';
  fullWidth?: boolean;
}

const PCheckBoxInput = (props: IProps) => {
  const { name, label, value, onChange } = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChange = ()=>{
    if (value === true) {
      onChange(false);
    } else if (value === false) {
      onChange(null);
    } else {
      onChange(true);
    }
  };


  return (
        <FormControlLabel
            label={label}
            control={(
                <Checkbox
                    checked={Boolean(value)}
                    indeterminate={value === null}
                    value={Boolean(value)}
                    onChange={handleChange}
                    name={name}
                    color="primary"
                />
            )}
        />
  );
};
export default PCheckBoxInput;
