import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getRemoteConfig } from 'firebase/remote-config';
import { getValue } from 'firebase/remote-config';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID
};

export const appSettings = {
  appName: 'ioTecPay'
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 60000; //3600000;

remoteConfig.defaultConfig = {
  'enable_collection_preview': false,
  'enable_wallet_email_notifications': false,
  'feedback_survey_code': 'customer_feedback_001',
  'feedback_survey_url': 'www.iotec.io',
  'feedback_survey_title': 'We value your feedback',
  'feedback_survey_message': 'Please take a moment to share your experience using our app. It won\'t take more than 5 minutes. Your insights will help us improve our services.',
};

export const remoteConfigData = {
  enableCollectionPreview: () => {
    return getValue(remoteConfig, 'enable_collection_preview').asBoolean();
  },
  enableWalletEmailNotifications: () => {
    return getValue(remoteConfig, 'enable_wallet_email_notifications').asBoolean();
  },
  feedbackSurveyCode: () => {
    return getValue(remoteConfig, 'feedback_survey_code').asString();
  },
  feedbackSurveyUrl: () => {
    return getValue(remoteConfig, 'feedback_survey_url').asString();
  },
  feedbackSurveyTitle: () => {
    return getValue(remoteConfig, 'feedback_survey_title').asString();
  },
  feedbackSurveyMessage: () => {
    return getValue(remoteConfig, 'feedback_survey_message').asString();
  },
};
