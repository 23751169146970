import { createSvgIcon } from '@mui/material/utils';

const MoneyIn = createSvgIcon(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="100%"
            viewBox="0 0 1024 1024"
        >
            <path fill="currentColor"
                  d="
M547.531372,1.000000
	C548.000000,59.636711 548.000000,118.273422 548.000000,177.490921
	C550.007629,177.490921 551.763672,177.481216 553.519531,177.492386
	C576.174927,177.636475 598.872131,176.974762 621.474609,178.144028
	C647.063354,179.467773 662.016296,209.854858 647.406982,230.937042
	C631.965698,253.219604 615.192383,274.580933 598.940247,296.299255
	C580.192749,321.352203 561.340332,346.326691 542.611206,371.393372
	C534.099670,382.785187 523.188171,388.938080 508.695038,387.208344
	C498.386078,385.977966 490.323853,380.625946 484.178864,372.439545
	C449.707611,326.516235 415.192780,280.625031 380.906067,234.564117
	C366.936829,215.797775 371.692474,192.377258 391.910492,181.877090
	C397.665131,178.888412 404.938293,177.837997 411.557495,177.680634
	C433.208282,177.165894 454.878601,177.473129 476.541412,177.456482
	C476.864685,177.456223 477.187897,177.355484 477.998840,177.220428
	C477.998840,118.843163 477.998840,60.390236 477.999420,1.468657
	C501.020905,1.000000 524.041809,1.000000 547.531372,1.000000
z"/>
            <path fill="currentColor"
                  d="
M973.000000,413.983063
	C985.149963,413.983063 996.799927,413.983063 1008.780151,413.983063
	C1008.780151,534.199707 1008.780151,654.241150 1008.780151,774.647461
	C967.769836,774.647461 926.712769,774.647461 885.354248,774.647461
	C885.354248,807.791504 885.354248,840.574036 885.354248,873.841125
	C843.830200,873.841125 802.605225,873.841125 760.960022,873.841125
	C760.960022,918.377869 760.960022,962.432556 760.960022,1006.728271
	C512.637756,1006.728271 264.738129,1006.728271 16.467913,1006.728271
	C16.467913,886.729309 16.467913,766.692017 16.467913,646.177856
	C57.690285,646.177856 99.076935,646.177856 140.853027,646.177856
	C140.853027,601.637329 140.853027,557.570251 140.853027,513.075623
	C182.308167,513.075623 223.375092,513.075623 264.851440,513.075623
	C264.851440,479.915680 264.851440,447.180939 264.851440,413.983063
	C501.008636,413.983063 736.754333,413.983063 973.000000,413.983063
M886.917969,704.842529
	C895.185120,704.842529 903.452271,704.842529 912.035278,704.842529
	C912.035278,690.263062 912.035278,676.326843 912.035278,661.892517
	C921.252686,661.892517 930.152161,661.892517 938.802979,661.892517
	C938.802979,616.331055 938.802979,571.274658 938.802979,525.843567
	C929.679321,525.843567 920.926819,525.843567 911.837402,525.843567
	C911.837402,511.705719 911.837402,497.942139 911.837402,484.096466
	C728.432983,484.096466 545.380493,484.096466 362.218384,484.096466
	C362.218384,493.776917 362.218384,503.195862 362.218384,513.131226
	C536.635132,513.131226 710.844849,513.131226 885.160461,513.131226
	C885.160461,515.811584 885.159668,517.974182 885.160461,520.136841
	C885.183472,581.633057 885.206665,643.129211 885.160645,705.608276
	C885.160645,727.620667 885.160645,749.633057 885.160645,771.645447
	C885.434814,771.644226 885.708984,771.643005 885.983154,771.641785
	C885.983154,749.408813 885.983154,727.175781 886.917969,704.842529
M86.096390,829.500000
	C86.096390,851.077820 86.096390,872.655701 86.096390,894.819824
	C95.637634,894.819824 104.520714,894.819824 113.766792,894.819824
	C113.766792,909.156372 113.766792,922.904053 113.766792,936.659058
	C297.172058,936.659058 480.189270,936.659058 663.553955,936.659058
	C663.553955,922.541382 663.553955,908.763306 663.553955,894.458801
	C672.818115,894.458801 681.745605,894.458801 690.506409,894.458801
	C690.506409,848.699524 690.506409,803.475525 690.506409,757.944580
	C681.338318,757.944580 672.553284,757.944580 663.199463,757.944580
	C663.199463,743.774475 663.199463,729.902161 663.199463,716.117554
	C479.734070,716.117554 296.846588,716.117554 113.577675,716.117554
	C113.577675,730.156250 113.577675,743.917847 113.577675,758.150513
	C104.296349,758.150513 95.386909,758.150513 86.096390,758.150513
	C86.096390,781.917908 86.096390,805.208984 86.096390,829.500000
M479.129303,623.554199
	C513.846863,609.815796 549.760925,617.283691 575.734741,643.426086
	C577.229675,644.930725 579.997742,645.886658 582.176025,645.891541
	C640.000732,646.020935 697.825806,645.991455 755.650818,645.989014
	C757.395081,645.988892 759.139343,645.988953 761.065857,645.988953
	C761.065857,699.012268 761.065857,751.289490 761.065857,803.654297
	C770.128662,803.654297 778.994568,803.654297 788.143555,803.654297
	C788.143555,789.526428 788.143555,775.742249 788.143555,761.580566
	C797.189514,761.580566 805.953064,761.580566 814.880310,761.580566
	C814.880310,716.006104 814.880310,670.816040 814.880310,625.212402
	C805.789246,625.212402 797.045349,625.212402 787.813599,625.212402
	C787.813599,610.913513 787.813599,597.021240 787.813599,583.333740
	C604.264893,583.333740 421.217041,583.333740 237.859497,583.333740
	C237.859497,597.442017 237.859497,611.219116 237.859497,625.370728
	C228.793564,625.370728 220.030197,625.370728 211.089783,625.370728
	C211.089783,632.437927 211.089783,639.120178 211.089783,645.743958
	C211.748917,645.864563 212.066406,645.973572 212.383911,645.973633
	C289.872681,645.986206 367.361450,646.005371 444.850067,645.903870
	C446.570648,645.901611 448.711853,644.943909 449.940857,643.715515
	C458.271362,635.389832 467.646423,628.636719 479.129303,623.554199
z"/>
            <path fill="#FFFFFF"
                  d="
M86.096390,829.000000
	C86.096390,805.208984 86.096390,781.917908 86.096390,758.150513
	C95.386909,758.150513 104.296349,758.150513 113.577675,758.150513
	C113.577675,743.917847 113.577675,730.156250 113.577675,716.117554
	C296.846588,716.117554 479.734070,716.117554 663.199463,716.117554
	C663.199463,729.902161 663.199463,743.774475 663.199463,757.944580
	C672.553284,757.944580 681.338318,757.944580 690.506409,757.944580
	C690.506409,803.475525 690.506409,848.699524 690.506409,894.458801
	C681.745605,894.458801 672.818115,894.458801 663.553955,894.458801
	C663.553955,908.763306 663.553955,922.541382 663.553955,936.659058
	C480.189270,936.659058 297.172058,936.659058 113.766792,936.659058
	C113.766792,922.904053 113.766792,909.156372 113.766792,894.819824
	C104.520714,894.819824 95.637634,894.819824 86.096390,894.819824
	C86.096390,872.655701 86.096390,851.077820 86.096390,829.000000
M472.412628,814.200317
	C460.977295,772.500610 421.311859,745.289856 379.128357,750.206482
	C329.173401,756.028992 294.805176,800.699585 303.061615,850.565918
	C308.887634,885.753235 329.936798,909.672729 363.916229,919.485107
	C399.683044,929.813599 431.291504,920.166687 455.569092,891.411377
	C474.312836,869.210449 478.818359,843.108826 472.412628,814.200317
z"/>
            <path fill="#FFFFFF"
                  d="
M478.779358,623.710083
	C467.646423,628.636719 458.271362,635.389832 449.940857,643.715515
	C448.711853,644.943909 446.570648,645.901611 444.850067,645.903870
	C367.361450,646.005371 289.872681,645.986206 212.383911,645.973633
	C212.066406,645.973572 211.748917,645.864563 211.089783,645.743958
	C211.089783,639.120178 211.089783,632.437927 211.089783,625.370728
	C220.030197,625.370728 228.793564,625.370728 237.859497,625.370728
	C237.859497,611.219116 237.859497,597.442017 237.859497,583.333740
	C421.217041,583.333740 604.264893,583.333740 787.813599,583.333740
	C787.813599,597.021240 787.813599,610.913513 787.813599,625.212402
	C797.045349,625.212402 805.789246,625.212402 814.880310,625.212402
	C814.880310,670.816040 814.880310,716.006104 814.880310,761.580566
	C805.953064,761.580566 797.189514,761.580566 788.143555,761.580566
	C788.143555,775.742249 788.143555,789.526428 788.143555,803.654297
	C778.994568,803.654297 770.128662,803.654297 761.065857,803.654297
	C761.065857,751.289490 761.065857,699.012268 761.065857,645.988953
	C759.139343,645.988953 757.395081,645.988892 755.650818,645.989014
	C697.825806,645.991455 640.000732,646.020935 582.176025,645.891541
	C579.997742,645.886658 577.229675,644.930725 575.734741,643.426086
	C549.760925,617.283691 513.846863,609.815796 478.779358,623.710083
z"/>
            <path fill="#FFFFFF"
                  d="
M885.229858,704.625427
	C885.206665,643.129211 885.183472,581.633057 885.160461,520.136841
	C885.159668,517.974182 885.160461,515.811584 885.160461,513.131226
	C710.844849,513.131226 536.635132,513.131226 362.218384,513.131226
	C362.218384,503.195862 362.218384,493.776917 362.218384,484.096466
	C545.380493,484.096466 728.432983,484.096466 911.837402,484.096466
	C911.837402,497.942139 911.837402,511.705719 911.837402,525.843567
	C920.926819,525.843567 929.679321,525.843567 938.802979,525.843567
	C938.802979,571.274658 938.802979,616.331055 938.802979,661.892517
	C930.152161,661.892517 921.252686,661.892517 912.035278,661.892517
	C912.035278,676.326843 912.035278,690.263062 912.035278,704.842529
	C903.452271,704.842529 895.185120,704.842529 886.294189,704.737549
	C885.670349,704.632629 885.229858,704.625427 885.229858,704.625427
z"/>
            <path fill="currentColor"
                  d="
M472.557434,814.588257
	C478.818359,843.108826 474.312836,869.210449 455.569092,891.411377
	C431.291504,920.166687 399.683044,929.813599 363.916229,919.485107
	C329.936798,909.672729 308.887634,885.753235 303.061615,850.565918
	C294.805176,800.699585 329.173401,756.028992 379.128357,750.206482
	C421.311859,745.289856 460.977295,772.500610 472.557434,814.588257
z"/>
        </svg>,
        'MoneyIn'
)
;

export default MoneyIn;
