import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface IProps {
  name: string
  onChange: (event: { target: { name: string; value: string } }) => void;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, IProps>(
  function NumericFormatCustom(props, ref) {
    const { name, onChange, ...other } = props;

    return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                  onChange({
                    target: {
                      name: name,
                      value: values.value,
                    },
                  });
                }}
                thousandSeparator
                valueIsNumericString
            />
    );
  },
);

export default NumericFormatCustom;
