import { isEmpty } from 'lodash';
import { hasValue } from '../components/inputs/inputHelpers';

export const getInitials = (fullName = '') => {
  try {
    return fullName.split(' ').map((it) => it[0].toUpperCase()).join('');
  } catch (e) {
    return '';
  }
};

export const dataStrToArray = (str: string | string[]) => {
  if (!str || isEmpty(str)) return [];
  try {
    if (typeof str === 'string') return [str];
    return str;
  } catch (e) {
    return [];
  }
};

export function getRandomStr(max = 16) {
  const letters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let str = '';
  for (let i = 0; i < max; i++) {
    str += letters[Math.floor(Math.random() * max)];
  }
  return str;
}

export function toPascalCase(data: string) {
  return data.match(/[a-z]+/gi)
    .map((word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
    .join(' ');
}

export const trimString = (data?: string, len: number = 20) => {
  const trimmed = data?.substr(0, len);
  return data?.length > len ? `${trimmed}...` : trimmed;
};

export const trimGuid = (data: string) => data.substr(0, 8);

export function enumToArray(typeData: any) {
  return Object.keys(typeData);
}

// Split pascal case
export function camelPad(str: string) {
  return str
  // Look for long acronyms and filter out the last letter
    .replace(/([A-Z]+)([A-Z][a-z])/g, ' $1 $2')
  // Look for lower-case letters followed by upper-case letters
    .replace(/([a-z\d])([A-Z])/g, '$1 $2')
  // Look for lower-case letters followed by numbers
    .replace(/([a-zA-Z])(\d)/g, '$1 $2')
    .replace(/^./, (newStr) => newStr.toUpperCase())
  // Remove any white space left around the word
    .trim();
}

export function toTitleCase(str: string) {
  if (!hasValue(str)) {
    return str;
  }
  const splitStr = str.toLowerCase()
    .split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0)
      .toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
}

export function truncateString(str: string, maxChar: number = 45) {
  return str.length >= maxChar ? str.slice(0, maxChar - 1) + '...' : str.slice(0, maxChar);
}
